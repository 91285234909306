const SIGN_UP = "adminSignup";
const LOGIN = "tryLoginAdmin";
const FORGOT_PASSWORD = "adminEmailNotification";
const RESET_PASSWORD = "resetPassword";
const ADMIN_DETAIL = "getadminDetail";
const ADD_CARRIER_DETAIL = "addCarrierDetail";
const CARRIER_LIST = "giveCarrierList";
const CARRIER_SENT_EMAIL = "CarrierSendemail";
const CARRIER_EDIT = "editCarrierDetail";
const BLOCK_CARRIER = "BlockCarrierDetail";
const DELETE_CARRIER = "deleteStatuss";
const ADD_STAFF = "addStaffDetail";
const STAFF_LIST = "giveStaffList";
const STAFF_SEND_EMAIL = "StaffSendemail";
const STAFF_EDIT = "editStaffDetail";
const STAFF_BLOCK = "BlockStaffDeatil";
const STAFF_DELETE = "deleteStatus";
const ADD_CLIENT = "addClient";
const CLIENT_LIST = "giveclientList";
const CLIENT_SEND_MAIL = "Sendemail";
const CLIENT_EDIT = "editClientDetail";
const CLIENT_BLOCK = "blockClient";
const CLIENT_DELETE = "clientdeleteStatus";
const ADD_USER = "adduser";
const USER_LIST = "giveUserList";
const USER_SEND_EMIAL = "UserSendemail";
const USER_EDIT = "edituser";
const USER_BLOCK = "BlockuserDeatil";
const USER_DELETE = "deleteuserStatus";
const ADD_PRICE = "addPrize";
const PRICE_EDIT = "editPrize";
const PRICE_FETCH = "FetchPrice";
const EDIT_PROFILE = "editAdmin";
const PARTICULAR_CARRIER = "getParticularCarrier";
const PARTICULAR_CLIENT = "getParticularCalient";
const PARTICULAR_USER = "getParticularUser";
const UPLOAD_PROFILE_IMAGE = "uploadFile";
// staff
const LOGIN_STAFF = "loginStaff";
const GET_QUOTE = "getqoutesList";
const DELETE_QUOTE = "deleteQuetsRequest";
const ADD_QUOTE = "addQutes";
const GET_ORDERLIST = "getOdersDeatil";
const DELETE_ORDER = "deleteOders";
const STAFF_CARRIER_LIST = "StaffCarrierList";
const PARTICULAR_STAFF_DETAIL = "getParticularStaffDetail";
const TOTAL_COUNT = "calculateCount";
const ORDER_CARRIER_RATE = "editCarrierRate";
const FILTER_CATEGORY = "filterVehicleCategory";
const ACCEPT_CARRIER_PROPOSED = "AcceptqoutesAccept";
const STAFF_FORGOTPASSWORD = "StaffEmailNotification";
const STAFF_RESETPASSWORD = "resetPasswordStaff";
const STAFF_EDIT_ACCOUNT = "editStaff";
const STAFF_USER_LIST = "StaffgiveUserList";
const STAFF_CLIENT_LIST = "StaffgiveclientList";
const STAFF_CARRIERLIST = "StaffgiveCarrierList";
const STAFF_GETRESET_DETAIL = "getParticularStaffDetailId";
const STAFF_TRACKING_DETAIL = "getTrackingDetail";
const STAFF_FILTER_LOADING = "filterLoadingCategory";
const STAFF_ORDER_ARCHIVE = "getdeleteOdersDeatil";
const STAFF_SELECT_ORDER = "filterapi";
const STAFF_EDIT_QUOTE = "editQoutes";
const STAFF_PATICULAT_EDIT_DETAIL = "getstaffpartcularqoutesList";
//Common
const PHONENUMBER_CODE = "getEuropeCodedata";

// USER

const DECLINE_QUOTE_BY_USER_END_POINT = "decline_quote_by_user";

// CARRIER

const GET_CARRIER_QUOTES_END_POINT = "/get_carrier_quotes";
const APPROVE_QUOTE_BY_CARRIER_END_POINT = "/approve_quote_by_carrier";
const DECLINE_QUOTE_BY_CARRIER_END_POINT = "/decline_quote_by_carrier";
const DELETE_QUOTE_BY_CARRIER_END_POINT = "/delete_quote_by_carrier";
const EDIT_QUOTE_BY_CARRIER_END_POINT = "/edit_quote_by_carrier";
const LAUNCH_QUOTE_BY_CARRIER_END_POINT = "/launch_quote_mission_by_carrier";
const GET_CARRIER_ORDER_LIST_END_POINT = "/get_carrier_orders_list";
const GET_CARRIER_ARCHIVE_LIST = "/get_carrier_archives_list";
const ADD_RATING = "/add_ratings";
const SET_VIEW_ORDER_STATUS_CARRIER = "/set_view_order_status_carrier";
const GET_TRACKING_DETAILS = "/get_tracking_details";
const GET_USER_ORDER_LIST = "/get_user_orders_list";
const SET_VIEW_ORDER_STATUS_USER = "/set_view_order_status_user";
const DELETE_ORDER_BY_USER = "/delete_quote_by_user";
const GET_USER_ARCHIVE_LIST = "/get_user_archives_list";
const GET_CLIENT_ORDER_LIST = "/get_client_orders_list";
const GET_CLIENT_ARCHIVE_LIST = "/get_client_archives_list";
const GET_STAFF_ORDER_LIST = "/get_staff_orders_list";
const SEND_RESET_PASS_LINK = "/send_link_for_forget_pass";
const CONFIRM_RESET_PASSWORD_OTP = "/confirm_mail_otp_and_reset_password";
const DELETE_QUOTE_FROM_LIST = "/delete_carrier_quotes";
const GET_FILE_LINK = '/get_file_link';
const DELETE_FILE_LINK = '/delete_file';
const CONTACT_US = "/contact-us";
export {
  SIGN_UP,
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  ADMIN_DETAIL,
  ADD_CARRIER_DETAIL,
  BLOCK_CARRIER,
  CARRIER_LIST,
  CARRIER_SENT_EMAIL,
  CARRIER_EDIT,
  DELETE_CARRIER,
  EDIT_PROFILE,
  ADD_STAFF,
  STAFF_LIST,
  STAFF_SEND_EMAIL,
  STAFF_EDIT,
  STAFF_BLOCK,
  STAFF_DELETE,
  ADD_CLIENT,
  CLIENT_LIST,
  CLIENT_SEND_MAIL,
  CLIENT_EDIT,
  CLIENT_BLOCK,
  CLIENT_DELETE,
  ADD_USER,
  USER_LIST,
  USER_SEND_EMIAL,
  USER_EDIT,
  USER_BLOCK,
  USER_DELETE,
  ADD_PRICE,
  PRICE_EDIT,
  PRICE_FETCH,
  PARTICULAR_CARRIER,
  PARTICULAR_CLIENT,
  PARTICULAR_USER,
  UPLOAD_PROFILE_IMAGE,
  //
  LOGIN_STAFF,
  GET_QUOTE,
  DELETE_QUOTE,
  ADD_QUOTE,
  GET_ORDERLIST,
  DELETE_ORDER,
  STAFF_CARRIER_LIST,
  PARTICULAR_STAFF_DETAIL,
  TOTAL_COUNT,
  ORDER_CARRIER_RATE,
  FILTER_CATEGORY,
  ACCEPT_CARRIER_PROPOSED,
  STAFF_FORGOTPASSWORD,
  STAFF_RESETPASSWORD,
  STAFF_EDIT_ACCOUNT,
  STAFF_USER_LIST,
  STAFF_CLIENT_LIST,
  STAFF_CARRIERLIST,
  STAFF_GETRESET_DETAIL,
  STAFF_TRACKING_DETAIL,
  STAFF_FILTER_LOADING,
  STAFF_ORDER_ARCHIVE,
  STAFF_SELECT_ORDER,
  STAFF_EDIT_QUOTE,
  STAFF_PATICULAT_EDIT_DETAIL,
  //
  PHONENUMBER_CODE,
  GET_CARRIER_QUOTES_END_POINT,
  APPROVE_QUOTE_BY_CARRIER_END_POINT,
  DECLINE_QUOTE_BY_CARRIER_END_POINT,
  DELETE_QUOTE_BY_CARRIER_END_POINT,
  EDIT_QUOTE_BY_CARRIER_END_POINT,
  LAUNCH_QUOTE_BY_CARRIER_END_POINT,
  DECLINE_QUOTE_BY_USER_END_POINT,
  GET_CARRIER_ORDER_LIST_END_POINT,
  GET_CARRIER_ARCHIVE_LIST,
  ADD_RATING,
  SET_VIEW_ORDER_STATUS_CARRIER,
  GET_TRACKING_DETAILS,
  GET_USER_ORDER_LIST,
  SET_VIEW_ORDER_STATUS_USER,
  DELETE_ORDER_BY_USER,
  GET_USER_ARCHIVE_LIST,
  GET_CLIENT_ORDER_LIST,
  GET_CLIENT_ARCHIVE_LIST,
  GET_STAFF_ORDER_LIST,
  SEND_RESET_PASS_LINK,
  CONFIRM_RESET_PASSWORD_OTP,
  CONTACT_US,
  DELETE_QUOTE_FROM_LIST,
  GET_FILE_LINK,
  DELETE_FILE_LINK
};
