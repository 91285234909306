import { useState, useEffect, useContext, useRef } from "react";
import {
  Col,
  Row,
  Button,
  Table,
  Image,
  DropdownButton,
  ButtonGroup,
  Dropdown,
  Modal,
  Form,
  Container,
  Overlay,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import AWS from 'aws-sdk';
import { Link } from "react-router-dom";
import { FiPlusCircle, FiMoreVertical, FiClock } from "react-icons/fi";
import { TiDelete } from "react-icons/ti";

import { IoIosArrowDropdown } from "react-icons/io";
import LoadingContext from "../services/LoadingContext";
import { AppStoreContext } from "../../store/AppStore";

import EditIcon from "../images/edit.svg";
import TrashIcon from "../images/trash.svg";
import TruckIcon from "../images/truck.svg";
import LocationIcon from "../images/location.svg";
import DeliveryIcon from "../images/delivery.svg";
import MatIcon from "../images/maticon.svg";
import TaskIcon from "../images/task.svg";
import TruckWhiteIcon from "../images/truckwhite.svg";
import BreadCrumbHeader from "../customcomponents/BreadCrumbHeader";
import TextInputWithLabel from "../customcomponents/TextInputWithLabel";
import TextInputWithoutLabel from "../customcomponents/TextInputWithoutLabel";
import TextInputAreaWithLabel from "../customcomponents/TextInputAreaWithLabel";
import { QuotesDataContext } from "../services/QuotesData";
import * as SERVICE from "../services/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { FaLocationDot } from "react-icons/fa6";
import {
  country_PhoneNumberCode,
  deleteFileS3,
  staff_ClientList,
  upload_file_getLink,
} from "../services/ApiCalls";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import dayjs from "dayjs";
import axios from "axios";

const NewQouteModalOne = (props) => {
  const {
    test,
    setTest,
    activeTab,
    setActiveTab,
    //Loading hooks
    pickupLocation,
    setPickupLocation,
    reference,
    setReference,
    companyName,
    setCompanyName,
    address,
    setAddress,
    date,
    setDate,
    time,
    setTime,
    contactName,
    setContactName,
    contactPhone,
    setContactPhone,
    contactEmail,
    setContactEmail,
    loadingLatLong,
    setLoadingLatLong,
    //delivery hooks
    deliveryLocation,
    setDeliveryLocation,
    deliveryType,
    setDeliveryType,
    deliveryRefernce,
    setDeliveryRefernce,
    deliveryCompanyName,
    setDeliveryCompanyName,
    deliveryAddress,
    setDeliveryAddress,
    deliveryDate,
    setDeliveryDate,
    deliveryTime,
    setDeliveryTime,
    deliveryContactName,
    setDeliveryContactName,
    deliveryContactPhone,
    setDeliveryContactPhone,
    deliveryContactEmail,
    setDeliveryContactEmail,
    deliveryLatLong,
    setDeliveryLatLong,
    // address
    loadingState,
    setLoadingState,
    loadingCity,
    setLoadingCity,
    loadingPostcode,
    setLoadingPostcode,
    loadingCountry,
    setLoadingCountry,
    deliveryLoadingState,
    setDeliveryLoadingState,
    deliveryLoadingCity,
    setDeliveryLoadingCity,
    deliveryLoadingPostcode,
    setDeliveryLoadingPostcode,
    deliveryLoadingCountry,
    setDeliveryLoadingCountry,
    //General cargo
    materialName,
    setMaterialName,
    numberOfPallets,
    setNumberOfPallets,
    sizeOfPallets,
    setSizeOfPallets,
    heightOfPallets,
    setHeightOfPallets,
    totalWeightOfPallets,
    setTotalWeightOfPallets,
    otherInformation,
    setOtherInformation,
    // tanker
    adrReference,
    setAdrReference,
    quantity,
    setQuantity,
    selectOptions,
    setSelectOptions,
    // radio,
    // setAdrRadio,
    // refrigerated
    temprature,
    setTemperature,
    selectMsds,
    setSelectMsds,
    //add materail
    materials,
    setMaterials,
    savedMaterials,
    setSavedMaterials,
    refrigeratedMaterials,
    setRefrigeratedMaterials,
    // phoneNumberCode
    selectedCountryCode,
    setSelectedCountryCode,
    unLoadingCountryCode,
    setUnLoadingCountryCode,
    //for Saving the file
    fileName,
    setFileName,

    // For Staff - Search a client
    selectedCarrierID,
    setSelectedCarrierID,
    Submit,
    setSubmit,
  } = useContext(QuotesDataContext);

  const [radio, setAdrRadio] = useState("yes");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleRadio = (type) => {
    setAdrRadio(type);
  };

  const handleCheckboxChange = (type) => {
    setSelectOptions(type);
  };

  const handleMsds = (type) => {
    setSelectMsds(type);
  };

  const handleDeliveryType = (option) => {
    setDeliveryType(option);
  };

  const [errorCount, seterrCount] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [referenceError, setReferenceError] = useState(0);
  const [companyNameError, setCompanyNameError] = useState(0);
  const [addressError, setAddressError] = useState(0);
  const [dateError, setDateError] = useState(0);
  const [timeError, setTimeError] = useState(0);
  const [contactNameError, setContactNameError] = useState(0);
  const [contactPhoneError, setContactPhoneError] = useState(0);
  const [contactEmailError, setContactEmailError] = useState(0);

  const [deliveryRefernceError, setDeliveryRefernceError] = useState(0);
  const [deliveryCompanyNameError, setDeliveryCompanyNameError] = useState(0);
  const [deliveryAddressError, setDeliveryAddressError] = useState(0);
  const [deliveryDateError, setDeliveryDateError] = useState(0);
  const [deliveryTimeError, setDeliveryTimeError] = useState(0);
  const [deliveryContactNameError, setDeliveryContactNameError] = useState(0);
  const [deliveryContactPhoneError, setDeliveryContactPhoneError] = useState(0);
  const [deliveryContactEmailError, setDeliveryContactEmailError] = useState(0);
  const [deliveryTypeError, setDeliveryTypeError] = useState(0);

  // general
  const [numberOfPalletsError, setNumberOfPalletsError] = useState(0);
  const [sizeOfPalletsError, setSizeOfPalletsError] = useState(0);
  const [heightOfPalletsError, setHeightOfPalletsError] = useState(0);
  const [totalWeightOfPalletsError, setTotalWeightOfPalletsError] = useState(0);

  // tanker
  const [adrReferenceError, setAdrReferenceError] = useState(0);
  const [quantityError, setQuantityError] = useState(0);
  const [selectOptionsError, setSelectOptionsError] = useState(0);
  const [adrRadioError, setAdrRadioError] = useState(0);
  // refigrater
  const [temperatureError, setTemperatureError] = useState(0);

  const [carrierName, setCarrierName] = useState("");
  const [carrierEmail, setCarrierEmail] = useState("");

  const [carrierNameError, setCarrierNameError] = useState("");
  const [carrierEmailError, setCarrierEmailError] = useState("");
  const [msdsError, setMsdsError] = useState(0);
  const [activeTabError, setActiveTabError] = useState("");
  const [carrierList, setCarrierList] = useState([]);
  const [showList, setShowList] = useState(false);
  const { setIsLoading } = useContext(LoadingContext);
  const [selectedCarrierIDError, setSelectedCarrierIDError] = useState(0);
  const { userDetail } = useContext(AppStoreContext);
  const userRole = userDetail.profile;

  let count = 0;

  const fetchStaffCarrierList = (carrierName) => {
    const payload = {};
    staff_ClientList(payload)
      .then((res) => {
        const filteredCarrierList = res.result.clientList.filter((carrier) => {
          return (
            carrier.companyName
              .toLowerCase()
              .includes(carrierName.toLowerCase()) ||
            carrier.accountNumber.includes(carrierName)
          );
        });
        setCarrierList(filteredCarrierList);

        setShowList(true);
      })
      .catch((err) => {
        console.log("err in addStaffQuote = ", err);
      });
  };

  const handleSelectCarrier = (carrierName) => {
    setSelectedCarrierID(carrierName._id);
    setCarrierName(carrierName.companyName);
    setShowList(false);
  };

  useEffect(() => {
    onProfileValidation();
  }, [
    reference,
    companyName,
    address,
    date,
    time,
    contactName,
    contactPhone,
    contactEmail,
    deliveryRefernce,
    deliveryCompanyName,
    deliveryAddress,
    deliveryDate,
    deliveryTime,
    deliveryContactName,
    deliveryContactPhone,
    deliveryContactEmail,
    numberOfPallets,
    sizeOfPallets,
    heightOfPallets,
    totalWeightOfPallets,
    adrReference,
    quantity,
    temprature,
    otherInformation,
    selectMsds,
  ]);

  const onChancel = (props) => {
    props.close();
    setSubmit(false);
    setActiveTab("");
    setMaterialName("");
    setNumberOfPallets("");
    setSizeOfPallets("");
    setHeightOfPallets("");
    setTotalWeightOfPallets("");
    setOtherInformation("");
    setQuantity("");
    setSelectOptions([]);
    setTemperature("");
    setSelectMsds("");
    //
    setReference("");
    setCompanyName("");
    setAddress("");
    setDate("");
    setTime("");
    setContactName("");
    setContactPhone("");
    setContactEmail("");
    //
    setDeliveryType("");
    setDeliveryRefernce("");
    setDeliveryCompanyName("");
    setDeliveryAddress("");
    setDeliveryDate("");
    setDeliveryTime("");
    setDeliveryContactName("");
    setDeliveryContactPhone();
    setDeliveryContactEmail("");
    // add
    setMaterials([]);
    setSavedMaterials([]);
    setRefrigeratedMaterials([]);
    setSelectedCarrierID("");
    setCarrierName("");
    setFileName("")
  };

  const onSubmit = (props) => {
    setSubmit(true);
    onProfileValidation();

    if (errorCount > 0) {
      console.log("errorCount if  ", errorCount);
      return;
    } else {
      props.onShowOtherModal();
    }
  };

  const onProfileValidation = async () => {
    let errorCount = 0;

    if (!activeTab) {
      errorCount++;
      let msg = "Please select a category.";
      setActiveTabError(msg);
    } else {
      setActiveTabError(null);
    }

    if (!selectedCarrierID && userRole === "Staff") {
      errorCount++;
      let msg = "Please select a client.";
      setSelectedCarrierIDError(msg);
    } else {
      setSelectedCarrierIDError(null);
    }

    // if (!reference) {
    //   errorCount++;
    //   let msg = "Please enter reference.";
    //   setReferenceError(msg);
    // } else {
    //   setReferenceError(null);
    // }

    if (!address) {
      errorCount++;
      let msg = "Please enter address.";
      setAddressError(msg);
    } else {
      setAddressError(null);
    }
    if (!date) {
      errorCount++;
      let msg = "Please enter date.";
      setDateError(msg);
    } else {
      setDateError(null);
    }

    if (!contactName) {
      errorCount++;
      let msg = "Please enter contact name.";
      setContactNameError(msg);
    } else {
      setContactNameError(null);
    }
    if (!contactPhone) {
      errorCount++;
      let msg = "Please enter a valid number.";
      setContactPhoneError(msg);
    } else {
      setContactPhoneError(null);
    }

    let contactEmailError = SERVICE.validateEmail(contactEmail);
    if (contactEmailError !== undefined) {
      errorCount++;
      let msg =
        contactEmailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setContactEmailError(msg);
    } else {
      setContactEmailError(null);
    }

    if (!deliveryType) {
      errorCount++;
      let msg = "Please select delivery type.";
      setDeliveryTypeError(msg);
    } else {
      setDeliveryTypeError(null);
    }

    if (!deliveryAddress) {
      errorCount++;
      let msg = "Please enter delivery address.";
      setDeliveryAddressError(msg);
    } else {
      setDeliveryAddressError(null);
    }
    if (!deliveryDate) {
      errorCount++;

      let msg = "Please enter delivery date.";
      setDeliveryDateError(msg);
    } else {
      if (new Date(deliveryDate) < new Date(date)) {
        errorCount++;
        const msg = "Please enter a valid delivery date that is today or later.";
        setDeliveryDateError(msg);
      } else {
        setDeliveryDateError(null);
      }
    }

    if (!deliveryContactName) {
      errorCount++;
      let msg = "Please enter delivery contact name.";
      setDeliveryContactNameError(msg);
    } else {
      setDeliveryContactNameError(null);
    }

    if (!deliveryContactPhone) {
      errorCount++;
      let msg = "Please enter a valid number.";
      setDeliveryContactPhoneError(msg);
    } else {
      setDeliveryContactPhoneError(null);
    }

    let deliveryContactEmailError = SERVICE.validateEmail(deliveryContactEmail);
    if (deliveryContactEmailError !== undefined) {
      errorCount++;
      let msg =
        deliveryContactEmailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setDeliveryContactEmailError(msg);
    } else {
      setDeliveryContactEmailError(null);
    }

    if (!time) {
      errorCount++;
      let msg = "Please enter time.";
      setTimeError(msg);
    } else {
      setTimeError(null);
    }

    if (!deliveryTime) {
      errorCount++;
      let msg = "Please enter delivery time.";
      setDeliveryTimeError(msg);
    } else {
      setDeliveryTimeError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
    console.log("errorCount", errorCount);
  };

  // General Cargo Function
  const [addMaterialModal, setAddMaterialModal] = useState(false);
  const [saveMaterailModal, setSaveMaterialModal] = useState(false);
  const [editMaterialId, setEditMaterialId] = useState(null);

  const handleSave = () => {
    if (
      !materialName ||
      !numberOfPallets ||
      !sizeOfPallets ||
      !totalWeightOfPallets
    ) {
      toast("Please fill all required fields");
      return;
    }
    if (editMaterialId !== null) {
      const updatedMaterials = materials.map((material) =>
        material.id === editMaterialId
          ? {
            ...material,
            materialName,
            numberOfPallets,
            sizeOfPallets,
            heightOfPallets,
            totalWeightOfPallets,
          }
          : material
      );
      setMaterials(updatedMaterials);
      setEditMaterialId(null);
    } else {
      const newMaterial = {
        id: ++count,
        materialName,
        numberOfPallets,
        sizeOfPallets,
        heightOfPallets,
        totalWeightOfPallets,
      };
      setMaterials([...materials, newMaterial]);
    }
    setMaterialName("");
    setNumberOfPallets("");
    setSizeOfPallets("");
    setHeightOfPallets("");
    setTotalWeightOfPallets("");
    setAddMaterialModal(false);
    setSaveMaterialModal(true);
  };

  const handleDelete = (id) => {
    const updatedMaterials = materials.filter((material) => material.id !== id);
    setMaterials(updatedMaterials);
  };

  const handleEdit = (id) => {
    setEditMaterialId(id);
    const materialToEdit = materials.find((material) => material.id === id);
    if (materialToEdit) {
      setMaterialName(materialToEdit.materialName);
      setNumberOfPallets(materialToEdit.numberOfPallets);
      setSizeOfPallets(materialToEdit.sizeOfPallets);
      setHeightOfPallets(materialToEdit.heightOfPallets);
      setTotalWeightOfPallets(materialToEdit.totalWeightOfPallets);
    }
    setAddMaterialModal(true);
  };

  // Tanker Function
  const [tankerAddMaterialModal, setTankerAddMaterialModal] = useState(false);
  const [tankerSaveMaterailModal, setTankerSaveMaterailModal] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [tankerEditItemId, setTankerEditItemId] = useState(null);
  const [selected_field_arr, setSelected_arr] = useState([]);
  const [preselected_Item, setpreselectedItem] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const handleCheckbox = (option, edit) => {
    if (edit) {
      if (preselected_Item.includes(option)) {
        const filteredArr = preselected_Item.filter((item) => item !== option);
        setpreselectedItem([...filteredArr]);
      } else {
        setpreselectedItem([...preselected_Item, option]);
      }
    } else {
      if (selectedOptions.includes(option)) {
        setSelectedOptions(selectedOptions.filter((item) => item !== option));
      } else {
        setSelected_arr([...selectedOptions, option]);

        setSelectedOptions([...selectedOptions, option]);
      }
    }
  };

  const handleDeleteSavedMaterial = (id) => {
    const updatedSavedMaterials = savedMaterials.filter(
      (material) => material.id !== id
    );
    setSavedMaterials(updatedSavedMaterials);
  };

  const handleEditSavedMaterial = (id, item) => {
    setIsEdit(true);
    setTankerEditItemId(id);
    setSelected_arr(item);
    const materialToEdit = savedMaterials.find(
      (material) => material.id === id
    );
    if (materialToEdit) {
      setpreselectedItem(materialToEdit.selectedOptions);
      setMaterialName(materialToEdit.materialName);
      setAdrRadio(materialToEdit.ADR == "Yes" ? "yes" : "no");
      setAdrReference(materialToEdit.adrReference);
      setQuantity(materialToEdit.quantity);
      setSelectOptions(materialToEdit.selectedOptions);
      if (materialToEdit.selectedOptions.some((item) => item == "MSDS included")) {
        console.log(materialToEdit  ,  'CHECK THE METRILA TO EDIT --')
        setFileName(materialToEdit?.msdsfile);
        setUploadProgress(100)
      } else {
        setFileName("");
        setUploadProgress(0)
      }
    }
    setTankerAddMaterialModal(true);
  };

  const handleSaveMaterial = () => {
    if (isEdit === false) {
      if (!materialName || !quantity || selectedOptions.length == 0) {
        toast("Please fill all required fields");
        return;
      }
    } else {
      if (!materialName || !quantity || preselected_Item.length == 0) {
        toast("Please fill all required fields");
        return;
      }
    }

    if (tankerEditItemId !== null) {
      const updatedSavedMaterials = savedMaterials.map((material) =>
        material.id === tankerEditItemId
          ? {
            ...material,
            materialName,
            ADR: radio === "yes" ? "Yes" : "No",
            adrReference: radio ? adrReference : "",
            quantity: quantity,
            selectedOptions: preselected_Item,
            msdsfile: preselected_Item.some((item) => item == "MSDS included") ? material.msdsfile : null
          }
          : material
      );
      setSavedMaterials(updatedSavedMaterials);
      setIsEdit(false);
      setMaterialName("");
      setAdrRadio("");
      setAdrReference("");
      setQuantity("");
      setSelectedOptions([]);
      setSelectOptions([])
      setpreselectedItem([])
      setTankerEditItemId(null);
      setFileName("");
      setUploadProgress(0)
    } else {
      const newMaterial = {
        id: ++count,
        materialName,
        ADR: radio == "yes" ? "Yes" : "No",
        adrReference: radio == "yes" ? adrReference : "",
        quantity: quantity,
        selectedOptions: selectedOptions,
        msdsfile: fileName || null
      };

      setSavedMaterials([...savedMaterials, newMaterial]);
      setAdrRadio("");
      setMaterialName("");
      setQuantity("");
      setAdrReference("");
      setSelectedOptions([]);
      setFileName("");
      setUploadProgress(0)
    }

    setTankerAddMaterialModal(false);
    setTankerSaveMaterailModal(true);
  };

  // REFIG
  const [refrigeratedAddMaterialModal, setRefrigeratedAddMaterialModal] =
    useState(false);
  const [refrigeratedSaveMaterailModal, setRefrigeratedSaveMaterialModal] =
    useState(false);
  const [msds, setMsds] = useState("Yes");

  const handleMSDS = (type) => {
    setMsds(type);
  };

  const handleRefrigeratedSave = () => {
    setRefrigeratedAddMaterialModal(true);
    if (
      !materialName ||
      !numberOfPallets ||
      !sizeOfPallets ||
      // !heightOfPallets ||
      !totalWeightOfPallets ||
      !temprature
    ) {
      toast("Please fill all required fields");
      return;
    }
    if (editMaterialId !== null) {
      const updatedRefrigeratedMaterials = refrigeratedMaterials.map(
        (material) =>
          material.id === editMaterialId
            ? {
              ...material,
              materialName,
              numberOfPallets,
              sizeOfPallets,
              heightOfPallets,
              totalWeightOfPallets,
              temprature,
              msds,
              msdsfile: fileName || null
            }
            : material
      );
      setRefrigeratedMaterials(updatedRefrigeratedMaterials);
      setEditMaterialId(null);
    } else {
      const newRefrigeratedMaterial = {
        id: ++count,
        materialName,
        numberOfPallets,
        sizeOfPallets,
        heightOfPallets,
        totalWeightOfPallets,
        temprature,
        msds,
        msdsfile: fileName || null
      };
      setRefrigeratedMaterials([
        ...refrigeratedMaterials,
        newRefrigeratedMaterial,
      ]);
    }
    // Reset input states and modals
    resetInputStates();
    setRefrigeratedAddMaterialModal(false);
    setRefrigeratedSaveMaterialModal(true);
  };

  const handleRefrigeratedDelete = (id) => {
    const updatedRefrigeratedMaterials = refrigeratedMaterials.filter(
      (material) => material.id !== id
    );
    setRefrigeratedMaterials(updatedRefrigeratedMaterials);
  };

  const handleRefrigeratedEdit = (id) => {
    setEditMaterialId(id);
    const refrigeratedMaterialToEdit = refrigeratedMaterials.find(
      (material) => material.id === id
    );
    if (refrigeratedMaterialToEdit) {
      // Set input states for editing
      setInputStates(refrigeratedMaterialToEdit);
    }
    setRefrigeratedAddMaterialModal(true);
  };

  const resetInputStates = () => {
    setMaterialName("");
    setNumberOfPallets("");
    setSizeOfPallets("");
    setHeightOfPallets("");
    setTotalWeightOfPallets("");
    setTemperature("");
    setFileName("");
    setUploadProgress(0)
  };

  const setInputStates = (material) => {
    setMaterialName(material.materialName);
    setNumberOfPallets(material.numberOfPallets);
    setSizeOfPallets(material.sizeOfPallets);
    setHeightOfPallets(material.heightOfPallets);
    setTotalWeightOfPallets(material.totalWeightOfPallets);
    setTemperature(material.temprature);
  };

  useEffect(() => {
    getCounrtyCodeList();

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB3cLetAkii900cvYPIrvxILEJ6qUhpG4I&callback=initMap&libraries=places`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleSelect = (value, placeId) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: value }, async (results, status) => {
      if (status === "OK") {
        const latLng = results[0].geometry.location;

        const service = new window.google.maps.places.PlacesService(
          document.createElement("div")
        );
        service.getDetails({ placeId }, (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            let state = "";
            let city = "";
            let country = "";
            let countryShort = "";
            let postalCode = "";

            for (const component of place.address_components) {
              if (component.types.includes("administrative_area_level_1")) {
                state = component.long_name;
              }
              if (
                component.types.includes("locality") ||
                component.types.includes("sublocality") ||
                component.types.includes("postal_town")
              ) {
                city = component.long_name;
              }
              if (component.types.includes("country")) {
                country = component.long_name;
                countryShort = component.short_name;
              }
              if (component.types.includes("postal_code")) {
                postalCode = component.long_name;
              }
            }

            setLoadingLatLong(latLng.toJSON());
            setAddress(value + " " + postalCode);
            setLoadingState(state);
            setLoadingCity(city);
            setLoadingCountry(countryShort);
            setLoadingPostcode(postalCode);
          }
        });
      } else {
        console.error(
          "Geocode was not successful for the following reason: " + status
        );
      }
    });
  };

  const handleSelectDelivery = (value, placeId) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: value }, async (results, status) => {
      if (status === "OK") {
        const latLng = results[0].geometry.location;

        const service = new window.google.maps.places.PlacesService(
          document.createElement("div")
        );
        service.getDetails({ placeId }, (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            let state = "";
            let city = "";
            let country = "";
            let countryShort = "";
            let postalCode = "";

            for (const component of place.address_components) {
              if (component.types.includes("administrative_area_level_1")) {
                state = component.long_name;
              }
              if (
                component.types.includes("locality") ||
                component.types.includes("sublocality") ||
                component.types.includes("postal_town")
              ) {
                city = component.long_name;
              }
              if (component.types.includes("country")) {
                country = component.long_name;
                countryShort = component.short_name;
              }
              if (component.types.includes("postal_code")) {
                postalCode = component.long_name;
              }
            }

            setDeliveryLatLong(latLng.toJSON());
            setDeliveryAddress(value + " " + postalCode);
            setDeliveryLoadingState(state);
            setDeliveryLoadingCity(city);
            setDeliveryLoadingCountry(countryShort);
            setDeliveryLoadingPostcode(postalCode);
          }
        });
      } else {
        console.error(
          "Geocode was not successful for the following reason: " + status
        );
      }
    });
  };

  const [phoneNumberCode, setPhoneNumberCode] = useState([]);
  const getCounrtyCodeList = () => {
    const payload = {};
    country_PhoneNumberCode(payload).then((res) => {
      setPhoneNumberCode(res.data);
    });
  };

  const handleLoadingCountryCode = (event) => {
    setSelectedCountryCode(event.target.value);
  };

  const handleUnloadingCountryCode = (event) => {
    setUnLoadingCountryCode(event.target.value);
  };

  const handleTimeChange = (newValue) => {
    // setTime(newValue);
    const formattedTime = moment(newValue.$d).format("HH:mm");
    setTime(formattedTime);
    console.log("Selected Time:", formattedTime);
  };

  const handledeliveryTimeChange = (newValue) => {
    // setTime(newValue);
    const formattedTime = moment(newValue.$d).format("HH:mm");
    setDeliveryTime(formattedTime);
    console.log("deliveryTime Time:", formattedTime);
  };
  const [isUploading, setIsUploading] = useState(false);

  // const handleFileChange = async (e) => {
  //   setUploadProgress(0)
  //   if (isUploading) {
  //     toast('A file is already uploading. Please wait until the current upload is finished.');
  //     return; // Prevent starting another upload if one is already in progress
  //   }
  //   const file = e.target.files?.[0];
  //   if (file) {
  //     if (file.type !== 'application/pdf') {
  //       alert('Only PDF files are allowed.');
  //       e.target.value = '';
  //       return;
  //     }
  //     setIsUploading(true);

  //     // AWS S3 Configuration
  //     const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME
  //     const REGION = process.env.REACT_APP_REGION

  //     AWS.config.update({
  //       accessKeyId: process.env.REACT_APP_ACCESS_KEY_S3,
  //       secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
  //     });

  //     const s3 = new AWS.S3({
  //       params: { Bucket: S3_BUCKET },
  //       region: REGION,
  //     });
  //     const fileNameToSave = `${Date.now()}_${file.name}`

  //     setFileName(fileNameToSave);

  //     const params = {
  //       Bucket: S3_BUCKET,
  //       Key: fileNameToSave,
  //       Body: file,
  //       ContentType: file.type,
  //       // ACL: 'public-read', 
  //     };
  //     const upload = s3.upload(params);
  //     upload.on('httpUploadProgress', (event) => {
  //       const progress = Math.round((event.loaded / event.total) * 100);
  //       setUploadProgress(progress);
  //     });

  //     try {
  //       const data = await upload.promise();
  //       console.log(data.Location, 'CHECK  THE FILE  URL  --')
  //     } catch (error) {
  //       console.error('Error uploading file:', error.message, error);
  //       alert('File upload failed.');
  //     } finally {
  //       setIsUploading(false);
  //     }
  //   }
  // };

  const handleFileChange = async (e) => {
    setUploadProgress(0);
    if (isUploading) {
      toast('A file is already uploading. Please wait until the current upload is finished.');
      return;
    }

    const file = e.target.files?.[0];
    if (file) {
      if (file.type !== 'application/pdf') {
        alert('Only PDF files are allowed.');
        e.target.value = '';
        return;
      }

      setIsUploading(true);

      try {
        const formData = new FormData();
        formData.append('pdf', file);

        console.log('Uploading file:', file.name);
        const response = await upload_file_getLink(formData, (progress) => {
          setUploadProgress(progress);
          console.log(`Upload Progress: ${progress}%`);
        });

        if (response?.status === 1 && response?.data?.image) {
          const imageUrl = response.data.image;
          const fileName = imageUrl.split('/').pop();
          setFileName(fileName);
        } else {
          throw new Error(response?.msg || 'Failed to upload file');
        }
      } catch (error) {
        console.error('Error uploading file:', error.message, error);
        alert('File upload failed.');
      } finally {
        setIsUploading(false);
      }
    }
  };


  const deleteFile = async (fileNameToDelete) => {
    console.log(fileNameToDelete, 'file to delete --');
    if (!fileNameToDelete) {
      alert('No file name provided for deletion.');
      return;
    }
    let payload = {
      fileKey: fileNameToDelete,
    };

    try {
      const response = await deleteFileS3(payload);
      console.log('File deleted successfully:', response);
         setUploadProgress(0)
      setFileName("")
   
      alert('File deleted successfully!');
    } catch (error) {
      console.error('Error deleting file:', error);
      alert('File deletion failed.');
    }
  };


  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="newqoute_modal"
    >
      <Modal.Body>
        <h2>New quote</h2>
        {userRole === "Staff" && (
          <>
            <BreadCrumbHeader svg title="CLIENT" />
            <div className="mb-3 searchORbox">
              <div className="inputBox">
                <h4 className="modal_label">
                  Search a client <span className="requiresLable">*</span>
                </h4>
                <TextInputWithLabel
                  placeholder="Enter a name or ID"
                  type="text"
                  value={carrierName}
                  onChange={(event) => {
                    setCarrierName(event.target.value);
                    fetchStaffCarrierList(event.target.value);
                  }}
                />
                {selectedCarrierIDError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {selectedCarrierIDError}
                  </div>
                ) : null}
                <IoIosArrowDropdown
                  className="dropdownfilter"
                  onClick={() => {
                    showList
                      ? setShowList(false)
                      : fetchStaffCarrierList(carrierName);
                  }}
                />
              </div>
              {showList && (
                <div className="serachResult">
                  <ul>
                    {carrierList &&
                      carrierList.map((item, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => {
                              handleSelectCarrier(item);
                            }}
                          >
                            {item.companyName}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              )}
            </div>
          </>
        )}
        <BreadCrumbHeader image={TruckIcon} title="VEHICLE CATEGORY" />
        <h4 className="modal_label">
          Select a category <span className="requiresLable">*</span>
        </h4>
        <div className="tab-buttons">
          <Button
            onClick={() => handleTabClick("General cargo")}
            className={activeTab === "General cargo" ? "active" : ""}
          >
            General cargo
          </Button>
          <Button
            onClick={() => handleTabClick("Tanker")}
            className={activeTab === "Tanker" ? "active" : ""}
          >
            Tanker
          </Button>
          <Button
            onClick={() => handleTabClick("Refrigerated")}
            className={activeTab === "Refrigerated" ? "active" : ""}
          >
            Refrigerated
          </Button>
          {activeTabError && Submit ? (
            <div
              style={{
                fontFamily: "AvenirLTProLight",
                color: "#FF0000",
                opacity: 0.6,
              }}
            >
              {activeTabError}
            </div>
          ) : null}
        </div>

        <div className="tab-content">
          {activeTab === "General cargo" && (
            <>
              <BreadCrumbHeader image={MatIcon} title="MATERIAL INFORMATIONS" />
              {/* General Cargo */}
              {saveMaterailModal && materials && materials.length > 0 && (
                <div className="modiFy">
                  {materials.map((material) => (
                    <div key={material.id}>
                      <div className="mod-flex">
                        <span className="numberText">{++count}</span>
                        <Link
                          className="me-3 edlink"
                          onClick={() => handleEdit(material.id)}
                        >
                          <Image className="editimg" src={EditIcon} alt="" />
                        </Link>
                        <Link
                          className="edlink"
                          onClick={() => handleDelete(material.id)}
                        >
                          <Image
                            className="editimgt edlink"
                            src={TrashIcon}
                            alt=""
                          />
                        </Link>
                      </div>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th className="materialTD">Material</th>
                            <th>Number of pallets</th>
                            <th>Size of pallets</th>
                            <th>Height of pallets</th>
                            <th>Total gross weight</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="materialTD">
                              {material.materialName}
                            </td>
                            <td>{material.numberOfPallets}</td>
                            <td>{material.sizeOfPallets}</td>
                            <td>{material.heightOfPallets}</td>
                            <td>{material.totalWeightOfPallets}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  ))}
                </div>
              )}

              {addMaterialModal && (
                <div className="modiFy">
                  <div className="mod-flex mb-3">
                    <span className="numberText">{++count}</span>
                  </div>
                  <Row>
                    <Col lg={6} md={6}>
                      <TextInputWithoutLabel
                        name="text"
                        placeholder="Enter material name"
                        value={materialName}
                        onChange={(text) => setMaterialName(text.target.value)}
                      />
                    </Col>
                  </Row>

                  <div className="inputFlex">
                    <div>
                      <TextInputWithLabel
                        name="text"
                        type="number"
                        placeholder="0"
                        label="Number of pallets"
                        reqstart="*"
                        value={numberOfPallets}
                        onChange={(text) => {
                          setNumberOfPallets(text.target.value);
                          // SERVICE.setNumberValidation(
                          //   text.target.value,
                          //   setNumberOfPallets
                          // );
                        }}
                      />
                      {numberOfPalletsError && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {numberOfPalletsError}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <TextInputWithLabel
                        name="text"
                        placeholder="0"
                        label="Size of pallets"
                        reqstart="*"
                        value={sizeOfPallets}
                        onChange={(text) => setSizeOfPallets(text.target.value)}
                      />
                      {sizeOfPalletsError && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {sizeOfPalletsError}
                        </div>
                      ) : null}
                    </div>

                    <div>
                      <TextInputWithLabel
                        name="text"
                        placeholder="0"
                        label="Height of pallets"
                        value={heightOfPallets}
                        onChange={(text) => {
                          setHeightOfPallets(text.target.value);
                          // SERVICE.setNumberValidation(
                          //   text.target.value,
                          //   setHeightOfPallets
                          // );
                        }}
                      />
                      {heightOfPalletsError && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {heightOfPalletsError}
                        </div>
                      ) : null}
                    </div>

                    <div className="kgflex kgflexSTAR">
                      <TextInputWithLabel
                        name="text"
                        type="number"
                        placeholder="0"
                        label="Total gross weight"
                        reqstart="*"
                        value={totalWeightOfPallets}
                        onChange={(text) =>
                          setTotalWeightOfPallets(text.target.value)
                        }
                      />
                      <span className="kg">Kg</span>

                      {totalWeightOfPalletsError && Submit ? (
                        <div
                          style={{
                            fontFamily: "AvenirLTProLight",
                            color: "#FF0000",
                            opacity: 0.6,
                          }}
                        >
                          {totalWeightOfPalletsError}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <Button
                    className="btn-primaryAll btnPrimary px-5"
                    onClick={() => handleSave()}
                  >
                    Save
                  </Button>
                </div>
              )}

              <Button
                className="btn-primaryAll btnPrimary mb-3"
                onClick={() => {
                  setAddMaterialModal(!addMaterialModal)
                }}
              >
                + Add material
              </Button>

              <TextInputAreaWithLabel
                label="Other information"
                placeholder="Enter your text here"
                value={otherInformation}
                onChange={(text) => setOtherInformation(text.target.value)}
              />
            </>
          )}

          {/* TANKER  */}
          {activeTab === "Tanker" && (
            <>
              <BreadCrumbHeader image={MatIcon} title="MATERIAL INFORMATIONS" />
              {tankerSaveMaterailModal && savedMaterials && savedMaterials.length > 0 && (
                <div className="modiFy">
                  {savedMaterials.map((material) => (
                    <div key={material.id}>
                      <div className="mod-flex">
                        <span className="numberText">{++count}</span>
                        <Link
                          className="me-3 edlink"
                          onClick={() =>
                            handleEditSavedMaterial(
                              material.id,
                              material.selectedOptions
                            )
                          }
                        >
                          <Image className="editimg" src={EditIcon} alt="" />
                        </Link>
                        <Link
                          className="edlink"
                          onClick={() => handleDeleteSavedMaterial(material.id)}
                        >
                          <Image
                            className="editimgt edlink"
                            src={TrashIcon}
                            alt=""
                          />
                        </Link>
                      </div>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th style={{ minWidth: 350, maxWidth: 350 }}>
                              Material
                            </th>
                            <th style={{ minWidth: 80 }}>ADR</th>
                            <th>Quantity</th>
                            <th style={{ minWidth: 80 }}>MSDS included</th>
                            <th style={{ minWidth: 80 }}>Blacklisted</th>
                            <th style={{ minWidth: 80 }}>
                              Cleaning certificate needed
                            </th>
                            <th style={{ minWidth: 80 }}>Pump needed</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                minWidth: 350,
                                maxWidth: 350,
                                paddingRight: 5,
                              }}
                            >
                              {material.materialName}
                            </td>
                            <td>
                              {material && material.ADR == "Yes"
                                ? `Yes - ${material.adrReference}`
                                : "No"}
                            </td>
                            <td>{material.quantity} L </td>
                            <td>
                              {material.selectedOptions &&
                                material.selectedOptions.includes("MSDS included")
                                ? "Yes"
                                : "No"}
                            </td>
                            <td>
                              {material.selectedOptions &&
                                material.selectedOptions.includes("Blacklisted")
                                ? "Yes"
                                : "No"}
                            </td>
                            <td>
                              {material.selectedOptions &&
                                material.selectedOptions.includes(
                                  "Cleaning certificate"
                                )
                                ? "Yes"
                                : "No"}
                            </td>
                            <td>
                              {material.selectedOptions &&
                                material.selectedOptions.includes("Pump needed")
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                    </div>
                  ))}
                </div>
              )}

              {tankerAddMaterialModal && (
                <div className="modiFy">
                  <div className="mod-flex mb-3">
                    <span className="numberText">
                      {tankerEditItemId ? tankerEditItemId : ++count}
                    </span>
                  </div>
                  <Row>
                    <Col lg={6} md={6}>
                      <TextInputWithoutLabel
                        name="text"
                        placeholder="Enter material name"
                        value={materialName}
                        onChange={(text) => setMaterialName(text.target.value)}
                      />
                    </Col>
                  </Row>
                  <div className="custom_radio">
                    <h5>
                      ADR <span className="requiresLable">*</span>
                    </h5>
                    <label>
                      <input
                        type="radio"
                        name="ADR"
                        className="card-input-element"
                        checked={radio == "yes" && true}
                        onChange={() => handleRadio("yes")}
                      />
                      <div className="card-input">
                        <div className="panel-heading">Yes</div>
                      </div>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="ADR"
                        checked={radio == "no" && true}
                        className="card-input-element"
                        onChange={() => handleRadio("no")}
                      />
                      <div className="card-input">
                        <div className="panel-heading">No</div>
                      </div>
                    </label>
                  </div>

                  {radio == "yes" && (
                    <Row>
                      <Col lg={4} md={6}>
                        <TextInputWithLabel
                          name="text"
                          placeholder="Enter ADR Reference"
                          label="ADR Reference"
                          reqstart="*"
                          value={adrReference}
                          onChange={(text) =>
                            setAdrReference(text.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col lg={4} md={6}>
                      <div className="d-flex align-items-center ">
                        <TextInputWithLabel
                          name="text"
                          placeholder="0"
                          label="Quantity"
                          reqstart="*"
                          value={quantity}
                          onChange={(text) => setQuantity(text.target.value)}
                        />
                        <span className="ms-2 mt-2">L</span>
                      </div>
                    </Col>
                  </Row>

                  <div className="custom_radio">
                    <h5>
                      Select options <span className="requiresLable">*</span>
                    </h5>
                    <label>
                      <input
                        type="checkbox"
                        name="selectoption"
                        className="card-input-element"
                        onChange={() => handleCheckbox("MSDS included", isEdit)}
                      />

                      <div
                        className={`card-input ${isEdit === true
                          ? preselected_Item.some(
                            (item) => item == "MSDS included"
                          )
                            ? "tanker_selection_opt_bg_color"
                            : "tanker_selection_opt_bg_color_alternate2"
                          : selectOptions.some(
                            (item) => item == "MSDS included"
                          ) && "tanker_selection_opt_bg_color"
                          }`}
                      >
                        <div className="panel-heading">MSDS included</div>
                      </div>
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        name="selectoption"
                        className="card-input-element"
                        onChange={() => handleCheckbox("Blacklisted", isEdit)}
                      />

                      <div
                        className={`card-input ${isEdit === true
                          ? preselected_Item.some(
                            (item) => item == "Blacklisted"
                          )
                            ? "tanker_selection_opt_bg_color"
                            : "tanker_selection_opt_bg_color_alternate2"
                          : selectOptions.some(
                            (item) => item == "Blacklisted"
                          ) && "tanker_selection_opt_bg_color"
                          }`}
                      >
                        <div className="panel-heading">Blacklisted</div>
                      </div>
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        name="selectoption"
                        className="card-input-element"
                        onChange={() =>
                          handleCheckbox("Cleaning certificate", isEdit)
                        }
                      />
                      <div
                        className={`card-input ${isEdit === true
                          ? preselected_Item.some(
                            (item) => item == "Cleaning certificate"
                          )
                            ? "tanker_selection_opt_bg_color"
                            : "tanker_selection_opt_bg_color_alternate2"
                          : selectOptions.some(
                            (item) => item == "Cleaning certificate"
                          ) && "tanker_selection_opt_bg_color"
                          }`}
                      >
                        <div className="panel-heading">
                          Cleaning certificate needed
                        </div>
                      </div>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="selectoption"
                        className="card-input-element"
                        onChange={() => handleCheckbox("Pump needed", isEdit)}
                      />
                      <div
                        className={`card-input ${isEdit === true
                          ? preselected_Item.some(
                            (item) => item == "Pump needed"
                          )
                            ? "tanker_selection_opt_bg_color"
                            : "tanker_selection_opt_bg_color_alternate2"
                          : selectOptions.some(
                            (item) => item == "Pump needed"
                          ) && "tanker_selection_opt_bg_color"
                          }`}
                      >
                        <div className="panel-heading">Pump needed</div>
                      </div>
                    </label>
                  </div>
                  {
                    selectedOptions && selectedOptions.length > 0 && selectedOptions.includes("MSDS included") ?
                      (
                        <div style={{ ...styles.fileBox, margin: '0px 10px 10px 0px' }}>
                          <label htmlFor="fileInput" style={styles.label}>
                            <span style={styles.button}>Upload PDF  <span style={{ color: 'red', justifyContent: 'center', alignItems: 'center' }} >*</span></span>
                          </label>
                          <input
                            id="fileInput"
                            type="file"
                            accept="application/pdf"
                            onChange={(e) => {
                              console.log('Change f r')
                              handleFileChange(e)
                            }}
                            style={styles.input}
                          />
                          {fileName && (
                            <div style={styles.fileContainer}>
                              <p style={{ ...styles.fileName }}>
                                File: {fileName}
                              </p>
                              <span style={styles.deleteIcon} onClick={() => deleteFile(fileName)}>
                                <TiDelete />
                              </span>
                            </div>
                          )}

                          {uploadProgress > 0 && (
                            <div style={styles.progressContainer}>
                              <div
                                style={{
                                  ...styles.progressBar,
                                  width: `${uploadProgress}%`,
                                }}
                              />
                              <span style={styles.progressText}>{uploadProgress}%</span>
                            </div>
                          )}
                        </div>
                      )
                      : isEdit === true && preselected_Item.some((item) => item === "MSDS included") && (
                        <div style={{ ...styles.fileBox, margin: '0px 10px 10px 0px' }}>
                          <label htmlFor="fileInput" style={styles.label}>
                            <span style={styles.button}>Upload PDF  <span style={{ color: 'red', justifyContent: 'center', alignItems: 'center' }} >*</span></span>
                          </label>
                          <input
                            id="fileInput"
                            type="file"
                            accept="application/pdf"
                            onChange={handleFileChange}
                            style={styles.input}
                          />
                          {fileName && (
                            <div style={styles.fileContainer}>
                              <p style={styles.fileName}>File: {fileName}</p>
                              <span style={styles.deleteIcon} onClick={() => deleteFile(fileName)}>
                                <TiDelete />
                              </span>
                            </div>
                          )}
                          {uploadProgress > 0 && (
                            <div style={styles.progressContainer}>
                              <div
                                style={{
                                  ...styles.progressBar,
                                  width: `${uploadProgress}%`,
                                }}
                              />
                              <span style={styles.progressText}>{uploadProgress}%</span>
                            </div>
                          )}
                        </div>
                      )
                  }
                  <Button
                    className="btn-primaryAll btnPrimary px-5"
                    onClick={() => handleSaveMaterial()}
                  >
                    Save
                  </Button>
                </div>
              )}

              <Button
                className="btn-primaryAll btnPrimary mb-3"
                onClick={() => {
                  console.log(preselected_Item, '<<====PRE=========', selectOptions, '<<<========SELEC TED=== .')
                  setIsEdit(false);
                  setpreselectedItem([])
                  setMaterialName("");
                  setAdrRadio("");
                  setAdrReference("");
                  setQuantity("");
                  setSelectedOptions([]);
                  setTankerEditItemId(null);
                  setFileName("");
                  setUploadProgress(0)
                  setTankerAddMaterialModal(!tankerAddMaterialModal)
                }

                }
              >
                + Add material
              </Button>

              <TextInputAreaWithLabel
                label="Other information"
                placeholder="Enter your text here"
                value={otherInformation}
                onChange={(text) => setOtherInformation(text.target.value)}
              />
            </>
          )}

          {/* REFRIGERATED */}
          {activeTab === "Refrigerated" && (
            <>
              <BreadCrumbHeader image={MatIcon} title="MATERIAL INFORMATIONS" />

              {refrigeratedSaveMaterailModal && refrigeratedMaterials && refrigeratedMaterials.length > 0 && (
                <div className="modiFy">
                  {refrigeratedMaterials.map((material) => (
                    <div key={material.id}>
                      <div className="mod-flex">
                        <span className="numberText">{++count}</span>
                        <Link
                          className="me-3 edlink"
                          onClick={() => handleRefrigeratedEdit(material.id)}
                        >
                          <Image className="editimg" src={EditIcon} alt="" />
                        </Link>
                        <Link
                          className="edlink"
                          onClick={() => handleRefrigeratedDelete(material.id)}
                        >
                          <Image
                            className="editimgt edlink"
                            src={TrashIcon}
                            alt=""
                          />
                        </Link>
                      </div>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Material</th>
                            <th>Temperature</th>
                            <th>Number of pallets</th>
                            <th>Size of pallets</th>
                            <th>Height of pallets</th>
                            <th>Total weight</th>
                            <th>MSDS included </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr key={material.id}>
                            <td>{material.materialName}</td>
                            <td>{material.temprature}</td>
                            <td>{material.numberOfPallets}</td>
                            <td>{material.sizeOfPallets}</td>
                            <td>{material.heightOfPallets}</td>
                            <td>{`${material.totalWeightOfPallets} Kg`}</td>
                            <td>{material.msds == "Yes" ? "Yes" : "No"}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  ))}
                </div>
              )}

              {refrigeratedAddMaterialModal && (
                <div className="modiFy">
                  <div className="mod-flex mb-3">
                    <span className="numberText">{++count}</span>
                  </div>
                  <Row>
                    <Col lg={6} md={6}>
                      <TextInputWithoutLabel
                        name="text"
                        placeholder="Enter material name"
                        value={materialName}
                        onChange={(text) => setMaterialName(text.target.value)}
                      />
                    </Col>
                  </Row>

                  <div className="inputFlex">
                    <div className="kgflex kgflexSTAR">
                      <TextInputWithLabel
                        name="text"
                        placeholder="0"
                        label="Temperature"
                        // reqstart="*"
                        value={temprature}
                        onChange={(text) => setTemperature(text.target.value)}
                      />
                      <span>°C</span>
                    </div>

                    <div>
                      <TextInputWithLabel
                        name="text"
                        type="number"
                        placeholder="0"
                        label="Number of pallets"
                        reqstart="*"
                        value={numberOfPallets}
                        onChange={(text) =>
                          setNumberOfPallets(text.target.value)
                        }
                      />
                    </div>
                    <div>
                      <TextInputWithLabel
                        name="text"
                        placeholder="0"
                        label="Size of pallets"
                        reqstart="*"
                        value={sizeOfPallets}
                        onChange={(text) => setSizeOfPallets(text.target.value)}
                      />
                    </div>

                    <div>
                      <TextInputWithLabel
                        name="text"
                        placeholder="0"
                        label="Height of pallets"
                        value={heightOfPallets}
                        onChange={(text) =>
                          setHeightOfPallets(text.target.value)
                        }
                      />
                    </div>

                    <div className="kgflex kgflexSTAR">
                      <TextInputWithLabel
                        name="text"
                        placeholder="0"
                        type="number"
                        label="Total gross weight"
                        reqstart="*"
                        value={totalWeightOfPallets}
                        onChange={(text) =>
                          setTotalWeightOfPallets(text.target.value)
                        }
                      />
                      <span>Kg </span>
                    </div>
                  </div>

                  <div className="custom_radio">
                    <h5>
                      MSDS included <span className="requiresLable">*</span>
                    </h5>
                    <label>
                      <input
                        type="radio"
                        name="sds"
                        checked={msds == "Yes" && true}
                        className="card-input-element"
                        onClick={() => handleMSDS("Yes")}
                      />
                      <div className="card-input">
                        <div className="panel-heading">Yes</div>
                      </div>
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="sds"
                        checked={msds == "No" && true}
                        className="card-input-element"
                        onClick={() => handleMSDS("No")}
                      />
                      <div className="card-input">
                        <div className="panel-heading">No</div>
                      </div>
                    </label>
                  </div>
                  {
                    msds === 'Yes' && (
                      <div style={{ ...styles.fileBox, margin: '0px 10px 10px 0px' }}>
                        <label htmlFor="fileInput" style={styles.label}>
                          <span style={styles.button}>Upload PDF  <span style={{ color: 'red', justifyContent: 'center', alignItems: 'center' }} >*</span></span>
                        </label>
                        <input
                          id="fileInput"
                          type="file"
                          accept="application/pdf"
                          onChange={handleFileChange}
                          style={styles.input}
                        />
                        {fileName &&<> <p style={styles.fileName}>File: {fileName}</p>  <span style={styles.deleteIcon} onClick={() => deleteFile(fileName)}>
                                <TiDelete />
                              </span> </>  }
                        {uploadProgress > 0 && (
                          <div style={styles.progressContainer}>
                            <div
                              style={{
                                ...styles.progressBar,
                                width: `${uploadProgress}%`,
                              }}
                            />
                            <span style={styles.progressText}>{uploadProgress}%</span>
                          </div>
                        )}
                      </div>
                    )
                  }

                  <Button
                    className="btn-primaryAll btnPrimary px-5"
                    onClick={() => handleRefrigeratedSave()}
                  >
                    Save
                  </Button>
                </div>
              )}

              <Button
                className="btn-primaryAll btnPrimary mb-3"
                onClick={() =>
                  setRefrigeratedAddMaterialModal(!refrigeratedAddMaterialModal)
                }
              >
                + Add material
              </Button>

              <TextInputAreaWithLabel
                label="Other information"
                placeholder="Enter your text here"
                value={otherInformation}
                onChange={(text) => setOtherInformation(text.target.value)}
              />
            </>
          )}
        </div>

        <BreadCrumbHeader image={LocationIcon} title="LOADING INFORMATIONS" />

        {["checkbox"].map((type) => (
          <div key={`default-${type}`} className="mb-3 textColor">
            <Form.Check
              type={type}
              id={`default-${type}`}
              value={pickupLocation}
              checked={pickupLocation}
              label={`Appointment required at pick-up location`}
              onClick={() => setPickupLocation(!pickupLocation)}
            />
          </div>
        ))}

        <Row>
          <Col md={6}>
            <TextInputWithLabel
              label="Reference"
              placeholder="Enter a reference"
              type="text"
              // reqstart="*"
              value={reference}
              onChange={(text) => {
                setReference(text.target.value);
              }}
            />
            {referenceError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {referenceError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="Company name"
              placeholder="Enter a name"
              type="text"
              value={companyName}
              onChange={(text) => setCompanyName(text.target.value)}
            />
            {companyNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {companyNameError}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div>
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <TextInputWithLabel
                      {...getInputProps({
                        placeholder: "Enter an Address",
                        className: "location-search-input",
                        label: "Address",
                        reqstart: "*",
                      })}
                    />
                    {suggestions.length > 0 && (
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";

                          return (
                            <li
                              style={{ cursor: "pointer" }}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                              })}
                            >
                              <FaLocationDot />

                              <span>{suggestion.description}</span>
                            </li>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </PlacesAutocomplete>
            </div>

            {addressError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {addressError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <Row>
              <Col md={6}>
                <TextInputWithLabel
                  label="Date (dd-mm-yyyy)"
                  placeholder="DD.MM.YYYY"
                  type="date"
                  min={getTodayDate()}
                  reqstart="*"
                  value={date}
                  onChange={(text) => setDate(text.target.value)}
                />
                {dateError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {dateError}
                  </div>
                ) : null}
              </Col>
              <Col md={6}>
                {/* <TextInputWithLabel
                  label="Hour"
                  placeholder="HH:MM"
                  type="time"
                  value={time}
                  onChange={(text) => setTime(text.target.value)}
                /> */}
                <label class="form-label">
                  Hour<span style={{ color: "red" }}>*</span>
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      label=""
                      minutesStep={1}
                      // value={dayjs(time,'HH:mm')}
                      value={time ? dayjs(time, "HH:mm") : null} // Ensure the picker shows the correct time
                      onChange={handleTimeChange}
                    />
                  </DemoContainer>
                </LocalizationProvider>

                {timeError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: ".1em",
                    }}
                  >
                    {timeError}
                  </div>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={4}>
            <TextInputWithLabel
              label="Contact name"
              placeholder="Enter a name"
              type="text"
              reqstart="*"
              value={contactName}
              onChange={(text) => setContactName(text.target.value)}
            />
            {contactNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {contactNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6} lg={4}>
            <div className="counCode countFLEX">
              <Form.Select
                size="lg"
                value={selectedCountryCode}
                onChange={handleLoadingCountryCode}
              >
                {phoneNumberCode.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option && option.abbreviation} ({option.code})
                  </option>
                ))}
              </Form.Select>
              <TextInputWithLabel
                label="Contact phone"
                placeholder="Enter a phone number"
                type="text"
                reqstart="*"
                value={contactPhone}
                onChange={(text) => {
                  setContactPhone(text.target.value);
                }}
              />
            </div>

            {contactPhoneError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {contactPhoneError}
              </div>
            ) : null}
          </Col>
          <Col md={6} lg={4}>
            <TextInputWithLabel
              label="Contact email"
              placeholder="Enter an email"
              type="email"
              reqstart="*"
              value={contactEmail}
              onChange={(text) => setContactEmail(text.target.value)}
            />
            {contactEmailError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {contactEmailError}
              </div>
            ) : null}
          </Col>
        </Row>
        <div className="mb-2"></div>
        <BreadCrumbHeader image={DeliveryIcon} title="DELIVERY INFORMATIONS" />

        {["checkbox"].map((type) => (
          <div key={2} className="mb-3 textColor">
            <Form.Check
              type={type}
              id={2}
              value={deliveryLocation}
              checked={deliveryLocation}
              label={`Appointment required at delivery location`}
              onClick={() => setDeliveryLocation(!deliveryLocation)}
            />
          </div>
        ))}

        <div className="custom_radio">
          <h5>
            Delivery type <span className="requiresLable">*</span>
          </h5>
          <label>
            <input type="radio" name="product" className="card-input-element" />
            <div
              className="card-input"
              onClick={() => handleDeliveryType("standard")}
            >
              <div className="panel-heading">Standard</div>
            </div>
          </label>

          <label>
            <input type="radio" name="product" className="card-input-element" />
            <div
              className="card-input"
              onClick={() => handleDeliveryType("express")}
            >
              <div className="panel-heading">Express</div>
            </div>
          </label>
          {deliveryTypeError && Submit ? (
            <div
              style={{
                fontFamily: "AvenirLTProLight",
                color: "#FF0000",
                opacity: 0.6,
              }}
            >
              {deliveryTypeError}
            </div>
          ) : null}
        </div>

        <Row>
          <Col md={6}>
            <TextInputWithLabel
              label="Reference"
              placeholder="Enter a reference"
              type="text"
              // reqstart="*"
              value={deliveryRefernce}
              onChange={(text) => {
                setDeliveryRefernce(text.target.value);
              }}
            />
            {deliveryRefernceError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {deliveryRefernceError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <TextInputWithLabel
              label="Company name"
              placeholder="Enter a name"
              type="text"
              value={deliveryCompanyName}
              onChange={(text) => setDeliveryCompanyName(text.target.value)}
            />
            {deliveryCompanyNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {deliveryCompanyNameError}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div>
              <PlacesAutocomplete
                value={deliveryAddress}
                onChange={setDeliveryAddress}
                onSelect={handleSelectDelivery}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <TextInputWithLabel
                      {...getInputProps({
                        placeholder: "Enter an Address",
                        className: "location-search-input",
                        label: "Address",
                        reqstart: "*",
                      })}
                    />
                    {suggestions.length > 0 && (
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          return (
                            <li
                              style={{ cursor: "pointer" }}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                              })}
                            >
                              <FaLocationDot />
                              <span>{suggestion.description}</span>
                            </li>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </PlacesAutocomplete>
            </div>

            {deliveryAddressError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {deliveryAddressError}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <Row>
              <Col md={6}>
                <TextInputWithLabel
                  label="Date (dd-mm-yyyy)"
                  placeholder="DD.MM.YYYY"
                  type="date"
                  min={getTodayDate()}
                  reqstart="*"
                  value={deliveryDate}
                  onChange={(event) => {
                    setDeliveryDate(event.target.value);
                  }}


                />
                {deliveryDateError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {deliveryDateError}
                  </div>
                ) : null}
              </Col>
              <Col md={6}>
                <label class="form-label">
                  Hour<span style={{ color: "red" }}>*</span>
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      label=""
                      minutesStep={1}
                      value={time ? dayjs(deliveryTime, "HH:mm") : null}
                      onChange={handledeliveryTimeChange}
                    />
                  </DemoContainer>
                </LocalizationProvider>

                {deliveryTimeError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: ".1em",
                    }}
                  >
                    {deliveryTimeError}
                  </div>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={4}>
            <TextInputWithLabel
              label="Contact name"
              placeholder="Enter a name"
              type="text"
              reqstart="*"
              value={deliveryContactName}
              onChange={(text) => setDeliveryContactName(text.target.value)}
            />
            {deliveryContactNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {deliveryContactNameError}
              </div>
            ) : null}
          </Col>
          <Col md={6} lg={4}>
            <div className="counCode countFLEX">
              <Form.Select
                size="lg"
                value={unLoadingCountryCode}
                onChange={handleUnloadingCountryCode}
              >
                {phoneNumberCode.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option && option.abbreviation} ({option.code})
                  </option>
                ))}
              </Form.Select>
              <TextInputWithLabel
                label="Contact phone"
                placeholder="Enter a phone number"
                type="text"
                reqstart="*"
                value={deliveryContactPhone}
                onChange={(text) => {
                  setDeliveryContactPhone(text.target.value);
                }}
              />
            </div>

            {deliveryContactPhoneError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {deliveryContactPhoneError}
              </div>
            ) : null}
          </Col>
          <Col md={6} lg={4}>
            <TextInputWithLabel
              label="Contact email"
              placeholder="Enter an email"
              type="email"
              reqstart="*"
              value={deliveryContactEmail}
              onChange={(text) => setDeliveryContactEmail(text.target.value)}
            />
            {deliveryContactEmailError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {deliveryContactEmailError}
              </div>
            ) : null}
          </Col>
        </Row>

        <div className="modal_footer">
          <Button
            className="btn-primaryAll btnPrimaryoutline me-3"
            onClick={() => onChancel(props)}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            className="btn-primaryAll btnPrimary"
            onClick={() => onSubmit(props)}
          >
            Save informations
          </Button>
        </div>
      </Modal.Body>
      <ToastContainer />
    </Modal>
  );
};


const styles = {
  fileBox: {
    width: "300px",
    margin: "20px auto",
    fontFamily: "Arial, sans-serif",
  },
  label: {
    display: "inline-block",
    cursor: "pointer",
    borderRadius: "10px",
    border: "1px solid #F7CE6C",
    padding: "10px 20px",
    margin: '0px 0px 16px 0px'
  },
  button: {
    cursor: "pointer",
  },
  input: {
    display: "none",
  },
  fileName: {
    marginTop: "10px",
    fontSize: "14px",
  },
  progressContainer: {
    marginTop: "15px",
    height: "20px",
    width: "100%",
    borderRadius: "10px",
    backgroundColor: "#e0e0e0",
    position: "relative",
    overflow: "hidden",
  },
  progressBar: {
    height: "100%",
    backgroundColor: "#F7CE6C",
    transition: "width 0.2s ease",
  },
  progressText: {
    position: "absolute",
    top: "0",
    left: "50%",
    transform: "translateX(-50%)",
    fontSize: "12px",
    fontWeight: "bold",
    color: "#fff",
  },
  fileContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0',
    padding: '5px',
    borderRadius: '5px',
  },
  fileName: {
    margin: 0,
    color: '#333',
    fontSize: '14px',
    wordBreak: 'break-all',
  },
  deleteIcon: {
    cursor: 'pointer',
    color: 'red',
    fontSize: '25px',
    transition: 'color 0.3s ease',
  },
};


export default NewQouteModalOne;
