import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'; // Optional if using Bootstrap for styling

const TermsAndConditions = () => {
  return (
    <>
      <div className=''>
        <Container>
          <Row>
            <Col>
              <div style={{marginTop: `20px`  , marginBottom:'0px'}} className="termFlex">
                <h1>Terms and Conditions of Use and Sale</h1>
                <h5>Effective as of January 2, 2025</h5>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <div class="termsBox pb-5">
              <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
              <p style={{ textIndent: "0pt", }}>
                The present terms and conditions of use and sale (hereinafter referred to as
                the "Terms and Conditions") are entered into between:
              </p>
              <p
                style={{


                  textIndent: "0pt",

                }}
              >
                The company MOTTRUCK, with a capital of €5,000, whose registered office is
                located at 3 rue d’Enghien, 75010 Paris, registered with the Paris Trade and
                Companies Register under number 932 308 521, (hereinafter referred to as the
                "Company" or the "Operator").
              </p>
              <p
                style={{


                  textIndent: "0pt",
                  textAlign: "left"
                }}
              >
                And
              </p>

              <p style={{ textIndent: "0pt", }}>
                The User, the Client, and the Carrier of the Marketplace (hereinafter
                collectively referred to as the "Members" and individually as the "Member").
              </p>

              <ol id="l1">
                <li data-list-text={1}>
                  <h1 style={{ textAlign: "left" }}>PURPOSE</h1>
                  <p
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    The Company operates an online platform defined as the Marketplace,
                    accessible online and dedicated to connecting Carriers with Users or
                    Clients.
                  </p>
                  <p
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    These Terms and Conditions of MOTTRUCK aim to govern the terms and
                    conditions of use and sale of the Marketplace, as well as to define the
                    respective rights and obligations of the Company and the Members
                    connected via the Marketplace.
                  </p>
                  <p
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    Members are explicitly informed and acknowledge that the Marketplace is
                    intended exclusively for professionals. Any Order or use of the
                    Marketplace necessarily implies the unconditional acceptance by the
                    Members of these Terms and Conditions, including the annexes.
                  </p>

                </li>
                <li data-list-text={2}>
                  <h1 style={{ textAlign: "left" }}>DEFINITIONS</h1>
                  <h2
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    Carrier's Charter
                    <span className="p">
                      : refers to the document provided in Annex 2, defining the rules,
                      commitments, and best practices that Carriers must adhere to as part
                      of their collaboration with the Company. It serves as a reference to
                      ensure quality service.
                    </span>
                  </h2>
                  <h2
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    Client
                    <span className="p">
                      : refers to any legal or natural person who entrusts the Company with
                      the complete management of their road Transport operations. The
                      Company's support includes entering the request via the platform,
                      selecting a suitable Carrier, and tracking until delivery of the
                      Goods.
                    </span>
                  </h2>
                  <h2
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    Order
                    <span className="p">
                      : refers to the specific operation entrusted by a User or Client to a
                      Carrier via the Marketplace.
                    </span>
                  </h2>
                  <h2
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    Account
                    <span className="p">
                      : refers to the account created on the Marketplace by a Member during
                      their registration on the platform.
                    </span>
                  </h2>
                  <h2
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    Goods
                    <span className="p">
                      : refers to any goods transported as part of a delivery by a Carrier.
                      The list of Goods excluded from Transport is provided in Annex 1.
                    </span>
                  </h2>
                  <h2 style={{ paddingTop: "3pt", textIndent: "0pt", textAlign: "center" }}>
                    Marketplace
                    <span className="p">
                      : refers to the website operated by the Company (
                    </span>
                    <a href="http://www.mottruck.com/" className="s2" target="_blank">
                      https://www.mottruck.com
                    </a>
                    <a href="http://www.mottruck.com/" className="s3" target="_blank">
                      ).
                    </a>
                  </h2>

                  <h2
                    style={{ textIndent: "0pt", }}
                  >
                    Member / Members:{" "}
                    <span className="p">
                      refers to any person (User, Client, Carrier) with an Account and using
                      the Marketplace strictly for professional purposes.
                    </span>
                  </h2>
                  <h2
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    Mission
                    <span className="p">
                      : refers to the specific service entrusted by a Client or a User via
                      the Marketplace to a Carrier, formalized by the acceptance of the
                      Price Proposal.
                    </span>
                  </h2>
                  <h2
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    Platform
                    <span className="p">
                      : refers to the digital platform operated by the Company and
                      accessible by the Members.
                    </span>
                  </h2>
                  <h2
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    Price Proposal
                    <span className="p">
                      : refers to the all-inclusive price proposal (including taxes)
                      established by the Company on the Marketplace.
                    </span>
                  </h2>
                  <h2
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    Booking
                    <span className="p">
                      : refers to the acceptance of the Price Proposal and the Transport
                      conditions by a Client or a User.
                    </span>
                  </h2>
                  <h2
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    Services
                    <span className="p">
                      : refers to all the services provided by the Company as defined in
                      Article 4 of these Terms and Conditions.
                    </span>
                  </h2>
                  <h2
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    Company
                    <span className="p">
                      : represents the company MOTTRUCK, as defined in the section
                      introducing the parties.
                    </span>
                  </h2>

                  <h2
                    style={{ textIndent: "0pt", }}
                  >
                    Transport
                    <span className="p">
                      : refers to a transport service carried out by a Carrier.
                    </span>
                  </h2>

                  <h2
                    style={{ textIndent: "0pt", }}
                  >
                    Carrier
                    <span className="p">
                      : refers to any natural or legal person who has declared a commercial
                      activity, is registered with the trade register of their country of
                      registration, and offers professional transport services to Clients
                      and Users via the Marketplace.
                    </span>
                  </h2>
                  <h2
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    User
                    <span className="p">
                      : refers to any natural or legal person who uses the Platform without
                      having entered into a specific contract with the Company. The User is
                      responsible for creating their account and selecting a Carrier via the
                      Marketplace to perform the service.
                    </span>
                  </h2>
                  <h2
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    Validation
                    <span className="p">
                      : refers to the process defined in Article 4.2.6 of the Terms and
                      Conditions by which the User or Client confirms that the Mission
                      executed by the Carrier is completed.
                    </span>
                  </h2>

                </li>
                <li data-list-text={3}>
                  <h1 style={{ textAlign: "left" }}>REGISTRATION ON THE MARKETPLACE</h1>
                  <ol id="l2">
                    <li data-list-text="3.1">
                      <h2
                        style={{


                        }}
                      >
                        Conditions for Member Registration on the Marketplace
                      </h2>

                      <p
                        style={{


                        }}
                      >
                        To use the platform, the prospective Member must create an Account
                        by registering on the Marketplace.
                      </p>
                      <p

                      >
                        The Member responsible for registration must be at least eighteen
                        (18) years old, possess legal capacity, and act in a professional
                        capacity. The Member is required to contract and use the Marketplace
                        in accordance with these Terms and Conditions. The Member must
                        provide accurate information and promptly update it in the event of
                        any changes.
                      </p>
                      <p

                      >
                        Access to the created Account is protected by a username and
                        password chosen by the Member during registration on the
                        Marketplace. The Member is solely responsible for all use of their
                      </p>
                      <p
                        style={{
                          paddingTop: "3pt",


                        }}
                      >
                        username and password, ensuring their confidentiality and assuming
                        responsibility for any use of their Account.
                      </p>
                      <p

                      >
                        To validate the Member's registration, they must complete an
                        information form and confirm that they are not subject to insolvency
                        proceedings.
                      </p>
                      <h2

                      >
                        At the time of Account creation, the prospective Member agrees to
                        read and accept the Terms and Conditions by checking the
                        corresponding box displayed on the Platform during the Account
                        creation process.
                        <span className="p">
                          In the event that the Member provides false, inaccurate, outdated,
                          or incomplete data, the Company reserves the right to suspend or
                          terminate their Account and deny them future access to the
                          Services.
                        </span>
                      </h2>

                    </li>
                    <li data-list-text="3.2">
                      <h1
                        style={{

                          textAlign: "left"
                        }}
                      >
                        Conditions for Carrier Registration on the Marketplace
                      </h1>
                      <p

                      >
                        The conditions for Carrier registration on the Marketplace are in
                        addition to the registration requirements specified in the previous
                        article.
                      </p>
                      <p

                      >
                        To be listed as a Carrier on the Marketplace and visible to Users
                        and Clients, the Carrier must also accurately and completely fill in
                        their Account information and update it regularly.
                      </p>
                      <p

                      >
                        During registration on the Platform, the Carrier must provide:
                      </p>

                      <ul id="l3">
                        <li data-list-text="">
                          <p style={{ lineHeight: "14pt", textAlign: "left" }}>
                            A Kbis extract (company registration certificate) dated less
                            than three months,
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{ lineHeight: "14pt", textAlign: "left" }}>
                            A valid insurance certificate,
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{ textAlign: "left" }}>A completed information form,</p>
                        </li>
                        <li data-list-text="">
                          <p style={{ textAlign: "left" }}>
                            The Company's Carrier Charter signed.
                          </p>
                        </li>
                      </ul>

                      <p
                        style={{


                        }}
                      >
                        As the Marketplace operates internationally, the Carrier must comply
                        with these registration conditions to enable the Company to include
                        them in its database.
                      </p>
                      <p

                      >
                        For Carriers registered outside of France, they must also comply
                        with all obligations applicable under the laws of their country of
                        residence, as well as the obligations arising from their use of the
                        Marketplace.
                      </p>
                      <p

                      >
                        The Carrier agrees to possess the legal right to carry out the
                        activity they claim and to perform the Missions for which they offer
                        their services on the Marketplace.
                      </p>

                    </li>
                  </ol>
                </li>
                <br />
                <li data-list-text={4}>
                  <h1 style={{ textAlign: "left" }}>
                    DESCRIPTION OF THE MARKETPLACE SERVICES
                  </h1>
                  <ol id="l4">
                    <li data-list-text="4.1">
                      <h2
                        style={{


                        }}
                      >
                        Services Provided to Users: "User Offer"
                      </h2>

                      <p
                        style={{


                        }}
                      >
                        The User has free access to create an account and submit their
                        transport request. They are invited to enter the details of their
                        transport needs (type of goods, departure point, destination,
                        schedules, etc.) directly on the Platform.
                      </p>
                      <p

                      >
                        Once the request is submitted, the User will receive an
                        all-inclusive Price Proposal (including taxes) from the Company.
                      </p>
                      <p
                        style={{
                          paddingTop: "3pt",


                        }}
                      >
                        The User must then select the final Carrier based on the proposals
                        received, the availability of Carriers, and the conditions offered.
                        By accepting the choice of a Carrier, the User enters into an
                        agreement with the Carrier for the execution of the Transport.
                      </p>
                      <p

                      >
                        The Platform provides the necessary information to facilitate this
                        connection, but the User is solely responsible for the final
                        selection of the Carrier and the conclusion of the Transport
                        contract with them.
                      </p>

                      <p
                        style={{


                        }}
                      >
                        The Services include:
                      </p>

                      <ul id="l5">
                        <li data-list-text="">
                          <p style={{ lineHeight: "14pt", textAlign: "left" }}>
                            The ability for the User to create an Account on the
                            Marketplace;
                          </p>
                        </li>
                        <li data-list-text="">
                          <p
                            style={{

                              textAlign: "left"
                            }}
                          >
                            Connection with Carriers via the Marketplace for the execution
                            of Missions in France and abroad;
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{ lineHeight: "14pt", textAlign: "left" }}>
                            Issuance of invoices related to the Missions performed through
                            the Marketplace;
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{ textAlign: "left" }}>
                            Automatic recommendation tools for Carrier profiles.
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="4.2">
                      <h2
                        style={{


                        }}
                      >
                        Services Provided to Clients: "Client Offer"
                      </h2>
                      <p

                      >
                        The Client Offer is specifically designed for Clients wishing to
                        entrust the Company with the complete management of their road
                        transport needs. This service includes:
                      </p>
                      <ul id="l6">
                        <li data-list-text="">
                          <p
                            style={{

                              lineHeight: "14pt",
                              textAlign: "left"
                            }}
                          >
                            The Company entering the transport request directly on the
                            Platform;
                          </p>
                        </li>
                        <li data-list-text="">
                          <p
                            style={{

                              textAlign: "left"
                            }}
                          >
                            The selection of an appropriate Carrier by the Company, based on
                            the Client’s requirements and the Mission’s specifics;
                          </p>
                        </li>
                        <li data-list-text="">
                          <p
                            style={{

                              textAlign: "left"
                            }}
                          >
                            The Company’s monitoring of the transport process until the
                            final delivery of the Goods to the destination.
                          </p>
                        </li>
                      </ul>
                      <p
                        style={{
                          paddingTop: "12pt",


                        }}
                      >
                        To benefit from this "Client Offer," a contract is concluded between
                        the Client and the Company. This contract specifies the terms of the
                        service, including the responsibilities of each party, pricing
                        conditions, and commitments regarding quality and delivery
                        timelines.
                      </p>
                      <p

                      >
                        Through this solution, the Client delegates all transport operations
                        to the Company, which commits to providing a reliable, efficient,
                        and tailored service that meets the Client’s needs.
                      </p>
                    </li>
                    <li data-list-text="4.3">
                      <h2
                        style={{


                        }}
                      >
                        Services Provided to Carriers: "Carrier Offer"
                      </h2>
                      <p

                      >
                        The Carrier Offer is intended for transport professionals seeking
                        access to Mission opportunities via the Company’s Marketplace. By
                        joining the Platform, Carriers benefit from the following services:
                      </p>
                      <ul id="l7">
                        <li data-list-text="">
                          <h2
                            style={{



                            }}
                          >
                            Creation of a Professional Account
                            <span className="p">
                              : Carriers can create and manage a dedicated account on the
                              Marketplace, allowing them to receive and accept Mission
                              proposals.
                            </span>
                          </h2>
                        </li>
                        <li data-list-text="">
                          <h2
                            style={{


                            }}
                          >
                            Connection with Users/Clients
                            <span className="p">
                              : The Marketplace facilitates connections with Users and
                              Clients seeking suitable transport solutions for missions in
                              France and internationally.
                            </span>
                          </h2>
                        </li>
                        <li data-list-text="">
                          <h2
                            style={{


                            }}
                          >
                            Automatic Recommendation Tools
                            <span className="p">
                              : The platform offers advanced tools to promote Carriers’
                              profiles to clients based on specific criteria such as
                              location, availability, or type of service offered.
                            </span>
                          </h2>
                        </li>
                      </ul>
                    </li>
                  </ol>

                </li>
                <li data-list-text={5}>
                  <h1 style={{ paddingTop: "2pt", textAlign: "left" }}>
                    FUNCTIONING OF THE MARKETPLACE
                  </h1>
                  <ol id="l8">
                    <li data-list-text="5.1">
                      <h2
                        style={{


                        }}
                      >
                        Order Placement
                      </h2>

                      <p
                        style={{


                        }}
                      >
                        The User places an Order by providing the information required by
                        the Marketplace, particularly details about the characteristics of
                        the Goods. The User agrees to provide accurate and error-free
                        information (especially regarding the weight, number of separate
                        items, and dimensions of the Goods) to optimize the Marketplace’s
                        functionality.
                      </p>
                    </li>
                    <li data-list-text="5.2">
                      <h2
                        style={{


                        }}
                      >
                        Connection via the Marketplace
                      </h2>

                      <p
                        style={{


                        }}
                      >
                        The Company provides Users with access to European road Carriers
                        through its platform. The Company offers a selection of qualified
                        Carriers capable of performing the Mission according to the criteria
                        defined by the User when placing the Order.
                      </p>

                    </li>
                    <li data-list-text="5.3">
                      <h2
                        style={{

                        }}
                      >
                        Issuance of a Price Proposal
                      </h2>

                      <p
                        style={{


                        }}
                      >
                        Once the User submits the scope and terms of their Order on the
                        Marketplace, they may receive Price Proposals (including VAT) via
                        the Marketplace.
                      </p>
                      <p

                      >
                        The delivery must be carried out in accordance with the Order. Any
                        changes to the conditions agreed upon at the time of the User's
                        Order must be validated by the Company. These changes may lead to a
                        price adjustment.
                      </p>

                      <p
                        style={{


                        }}
                      >
                        Prices displayed on the Platform include all taxes.
                      </p>

                    </li>
                    <li data-list-text="5.4">
                      <h2
                        style={{

                        }}
                      >
                        Reservation
                      </h2>

                      <p
                        style={{


                        }}
                      >
                        Once the Transport conditions are agreed upon between the User and
                        the Carrier, the User confirms the Reservation by accepting the
                        Price Proposal on the Marketplace.
                      </p>
                      <p

                      >
                        The Carrier will begin performing their Mission once notified via
                        the Marketplace that the User has confirmed the Reservation.
                      </p>
                      <p

                      >
                        The User and the Carrier agree not to request cancellation of the
                        Reservation while the Mission is in progress.
                      </p>
                    </li>
                    <li data-list-text="5.5">
                      <h2
                        style={{


                        }}
                      >
                        Mission Validation
                      </h2>
                      <p

                      >
                        The User validates the Mission upon delivery of the Goods by signing
                        the delivery note presented by the Carrier.
                      </p>
                    </li>
                    <li data-list-text="5.6">
                      <h2
                        style={{


                        }}
                      >
                        Billing the User by the Company
                      </h2>

                      <p
                        style={{


                        }}
                      >
                        The Company issues an invoice to the User as soon as the Goods are
                        picked up. The payment deadline is set at 30 days from the invoice
                        date. In the event of a justified request for invoice correction,
                        the Company agrees to promptly issue a revised invoice.
                      </p>
                    </li>
                    <li data-list-text="5.7">
                      <h2
                        style={{


                        }}
                      >
                        Billing the Client by the Company
                      </h2>
                      <p
                        style={{
                          paddingTop: "3pt",


                        }}
                      >
                        Clients benefiting from the “Client Offer” receive a summary invoice
                        at the end of each month, detailing all Orders placed during the
                        period. This invoice specifies the services performed and the
                        corresponding amounts.
                      </p>

                      <p
                        style={{

                          textIndent: "0pt",
                          textAlign: "left"
                        }}
                      >
                        The invoice must be paid within 30 days from its issuance date.
                      </p>

                    </li>
                    <li data-list-text="5.8">
                      <h2
                        style={{

                        }}
                      >
                        Billing the Company by the Carrier
                      </h2>

                      <p
                        style={{


                        }}
                      >
                        The Carrier issues an invoice once delivery is completed, including
                        the CMR (Convention on the Contract for the International Carriage
                        of Goods by Road) or POD (Proof of Delivery) uploaded on the
                        Platform.
                      </p>
                      <p

                      >
                        Subject to the payment of the Mission fee by the User or Client and
                        the Mission Validation, the Company agrees to pay the Carrier the
                        corresponding fee within 30 days from the invoice date.
                      </p>

                      <h2
                        style={{

                          textIndent: "0pt",
                          textAlign: "left"
                        }}
                      >
                        5.8 Payment
                      </h2>

                      <p
                        style={{

                          textIndent: "0pt",
                          textAlign: "left"
                        }}
                      >
                        Payments are handled exclusively by bank transfer.
                      </p>
                      <p

                      >
                        The banking information of the parties is protected in accordance
                        with the applicable data protection laws.
                      </p>
                      <p

                      >
                        Members are responsible for verifying the accuracy of the banking
                        details provided during payment execution. The Company shall not be
                        held liable for any errors or omissions made by a Member.
                      </p>

                    </li>
                  </ol>
                </li>
                <br />
                <li data-list-text={6}>
                  <h1 style={{ textAlign: "left" }}>
                    EVALUATION AND RECOMMENDATION SYSTEM
                  </h1>
                  <ol id="l9">
                    <li data-list-text="6.1">
                      <p

                      >
                        At the end of each Mission, Clients and Users can evaluate the
                        Carrier they worked with by assigning a rating. The evaluation left
                        by a Client or User may appear on the Carrier's page.
                      </p>
                    </li>
                    <li data-list-text="6.2">
                      <p
                        style={{


                        }}
                      >
                        Carriers cannot evaluate a Client or User.
                      </p>

                    </li>
                    <li data-list-text="6.3">
                      <p
                        style={{


                        }}
                      >
                        Evaluations and recommendations must reflect objective
                        considerations and must no contain excessive or insulting elements
                        under any circumstances. If such content is found, the Company
                        reserves the right to delete the evaluation or recommendation.
                      </p>

                    </li>
                  </ol>
                </li>
                <br />
                <li data-list-text={7}>
                  <h1 style={{ textAlign: "left" }}>
                    ACCESS TO THE MARKETPLACE AND SERVICES
                  </h1>
                  <ol id="l10">
                    <li data-list-text="7.1">
                      <h2
                        style={{


                        }}
                      >
                        Technical Access to the Marketplace
                      </h2>

                      <p
                        style={{


                        }}
                      >
                        Marketplace Members are solely responsible for setting up the
                        necessary IT and telecommunications resources to access the
                        Marketplace. They bear the cost of telecommunications when accessing
                        the internet and using the Marketplace.
                      </p>

                      <p
                        style={{

                          textIndent: "0pt",
                          textAlign: "left"
                        }}
                      >
                        The Marketplace is accessible 24/7 to all Members.
                      </p>
                      <p
                        style={{
                          paddingTop: "3pt",


                        }}
                      >
                        The Company reserves the right, without prior notice or
                        compensation, to temporarily or permanently close the Marketplace
                        and/or access to one or more Services to carry out updates,
                        modifications, or changes to operational methods, servers, and hours
                        of accessibility, without this list being exhaustive.
                      </p>
                      <p

                      >
                        The Company reserves the right to make any modifications and
                        improvements to the Marketplace and Services that it deems necessary
                        or useful for the proper functioning of the Marketplace and its
                        Services.
                      </p>
                    </li>
                    <li data-list-text="7.2">
                      <h2
                        style={{


                        }}
                      >
                        Access Reserved for Members
                      </h2>

                      <p
                        style={{


                        }}
                      >
                        Access to the Marketplace and Services is exclusively reserved for
                        Members as defined in the General Terms and Conditions.
                      </p>
                      <p

                      >
                        In case of non-compliance with this article, the Company reserves
                        the right to:
                      </p>

                      <ul id="l11">
                        <li data-list-text="">
                          <p style={{ textAlign: "left" }}>
                            Permanently close the account;
                          </p>
                        </li>
                        <li data-list-text="">
                          <p
                            style={{

                              textAlign: "left"
                            }}
                          >
                            Require payment from the individual or entity of a penalty
                            amounting to at least €5,000, depending on the severity of the
                            reported facts;
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{ textAlign: "left" }}>
                            Take all necessary measures before the competent courts.
                          </p>
                        </li>
                      </ul>

                      <p
                        style={{


                        }}
                      >
                        These individuals remain solely responsible for the direct and
                        indirect damages they may cause due to their behavior and shall bear
                        the consequences alone.
                      </p>

                    </li>
                    <li data-list-text="7.3">
                      <h2
                        style={{

                        }}
                      >
                        Prohibition of Automated Extraction
                      </h2>
                      <p

                      >
                        The use of software or automated tools to extract or collect
                        information from the Company's Marketplace, particularly for the
                        automated extraction of User/Client data or information about the
                        Services offered by the Company, is strictly prohibited.
                      </p>
                      <p

                      >
                        Anyone engaging in such practices is subject to sanctions that may
                        be enforced by the Company.
                      </p>

                    </li>
                  </ol>
                </li>
                <br />
                <li data-list-text={8}>
                  <h1 style={{ textAlign: "left" }}>PRICING OF SERVICES</h1>
                  <p
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    Users/Clients pay the indicated price, including VAT, in exchange for
                    the completion of the Mission, which corresponds to the costs incurred
                    by the Company and the Carrier to execute the Mission, hereinafter
                    referred to as the "Service Fees." Service Fees are inclusive of all
                    taxes.
                  </p>
                  <p
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    Unless otherwise stated, the Mission price is fixed and final. Any
                    modification of the Mission leading to a change in financial terms must
                    first be approved by the Company.
                  </p>

                </li>
                <br />
                <li data-list-text={9}>
                  <h1 style={{ textAlign: "left" }}>DISPUTES</h1>
                  <ol id="l12">
                    <li data-list-text="9.1">
                      <h2
                        style={{


                        }}
                      >
                        Cancellation of an Order by the User or Client
                      </h2>

                      <p
                        style={{


                        }}
                      >
                        Any cancellation of an Order by the User/Client incurs a flat
                        administrative fee of €90 (including VAT).
                      </p>
                      <p
                        style={{
                          paddingTop: "3pt",


                        }}
                      >
                        If the cancellation occurs less than 48 hours before the Goods are
                        to be picked up, the User/Client must pay, in addition to the flat
                        administrative fee of €90 (including VAT), any expenses incurred by
                        the Carrier.
                      </p>

                    </li>
                    <li data-list-text="9.2">
                      <h2
                        style={{

                        }}
                      >
                        Cancellation of an Order by the Carrier
                      </h2>

                      <p
                        style={{


                        }}
                      >
                        Any Carrier canceling an Order less than 48 hours before the Goods
                        are to be picked up will be subject to a flat administrative fee of
                        €110 (including VAT).
                      </p>

                    </li>
                    <li data-list-text="9.3">
                      <h2
                        style={{

                        }}
                      >
                        Non-Compliant Reservation or Special Circumstances
                      </h2>

                      <p
                        style={{


                        }}
                      >
                        If the Transport cannot be completed or incurs additional costs due
                        to:
                      </p>

                      <ul id="l13">
                        <li data-list-text="">
                          <p
                            style={{

                              textAlign: "left"
                            }}
                          >
                            A non-compliant Reservation or Order (e.g., when the Goods to be
                            transported do not match the specifications indicated in the
                            Reservation);
                          </p>
                        </li>
                        <li data-list-text="">
                          <p
                            style={{

                              textAlign: "left"
                            }}
                          >
                            Special circumstances (e.g., insufficient packaging, absence of
                            the person handing over the Goods, or the closure of the store
                            holding the Goods).
                          </p>
                        </li>
                      </ul>
                      <p

                      >
                        The affected Users, Clients, or Carriers are encouraged to contact
                        the Company's customer service to resolve the situation or examine
                        potential refund or compensation arrangements that will be the
                        responsibility of the party liable for the situation.
                      </p>

                    </li>
                    <li data-list-text="9.4">
                      <h2
                        style={{

                        }}
                      >
                        Disputes Between Marketplace Members (Client, User, Carrier)
                      </h2>

                      <ol id="l14">
                        <li data-list-text="9.4.1">
                          <h2
                            style={{
                              paddingLeft: "35pt",
                              textIndent: "-27pt",
                              textAlign: "left"
                            }}
                          >
                            Reporting the Dispute to the Company's Customer Service
                          </h2>

                          <p
                            style={{

                              textIndent: "0pt",

                            }}
                          >
                            In the event of a disagreement between the User/Client and the
                            Carrier regarding the quality of the service, the scope, terms,
                            or completion stage of the Mission, they agree to inform the
                            Company through its customer service.
                          </p>
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            The User/Client may file a complaint with the Company. Carriers
                            are required to provide clear responses to determine the
                            responsibility of each party. In the event of a dispute, the
                            Carrier must issue a credit note to the Company.
                          </p>

                        </li>
                        <li data-list-text="9.4.2">
                          <h2
                            style={{
                              paddingLeft: "35pt",
                              textIndent: "-27pt",
                              textAlign: "left"
                            }}
                          >
                            Mediation Attempt
                          </h2>
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            Upon being contacted by the User/Client and/or Carrier, the
                            Company's customer service will act as a mediator for a maximum
                            period of 45 days (hereinafter referred to as the “Mediation
                            Period”), attempting to offer an amicable solution to resolve
                            their dispute.
                          </p>
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            During the Mediation Period, the parties agree to make their
                            best efforts to discuss and reach an amicable solution to their
                            dispute.
                          </p>

                        </li>
                        <li data-list-text="9.4.3">
                          <h2
                            style={{
                              paddingLeft: "35pt",
                              textIndent: "-27pt",
                              textAlign: "left"
                            }}
                          >
                            Outcome of the Mediation Period
                          </h2>

                          <p
                            style={{

                              textIndent: "0pt",

                            }}
                          >
                            The Company will end the Mediation Period as soon as the parties
                            have reached an amicable solution to their dispute.
                          </p>
                          <p
                            className="s4"
                            style={{


                              textIndent: "0pt",
                              lineHeight: "115%",

                            }}
                          >
                            If, at the end of the Mediation Period, the parties have not
                            reached an amicable resolution to their dispute, the Company
                            will terminate the mediation and will no longer be involved in
                            the dispute. The parties will then be responsible for resolving
                            their dispute on their own.
                          </p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <br />
                <li data-list-text={10}>
                  <h1
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "25pt",
                      textIndent: "-24pt",
                      textAlign: "left"
                    }}
                  >
                    COMMITMENTS
                  </h1>
                  <ol id="l15">
                    <li data-list-text="10.1">
                      <h2
                        style={{

                          paddingLeft: "35pt",
                          textIndent: "-24pt",
                          textAlign: "left"
                        }}
                      >
                        Commitments of Marketplace Members
                      </h2>

                      <ol id="l16">
                        <li data-list-text="10.1.1">
                          <p
                            style={{

                              textIndent: "0pt",

                            }}
                          >
                            Marketplace Members commit to accessing and using the
                            Marketplace and Services in compliance with applicable laws and
                            these General Terms and Conditions.
                          </p>
                        </li>
                        <li data-list-text="10.1.2">
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            Marketplace Members agree to complete all declarations and
                            formalities required for their activities and to meet all their
                            legal, social, administrative, and tax obligations, as well as
                            any specific obligations that may apply to them under the laws
                            governing their activities and use of the Services.
                          </p>
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            Upon request, Marketplace Members commit to promptly providing
                            the Company with any documentation proving their compliance with
                            the conditions outlined in this article.
                          </p>
                        </li>
                        <li data-list-text="10.1.3">
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            Marketplace Members commit to using the Marketplace fairly,
                            including refraining from circumventing the Marketplace and its
                            Services. Specifically, Members agree not to contract a Mission
                            outside the Marketplace.
                          </p>
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            All Members are prohibited from extracting any Content from the
                            Marketplace for similar or competing activities or for
                            recruitment purposes.
                          </p>

                        </li>
                      </ol>
                    </li>
                    <li data-list-text="10.2">
                      <h2
                        style={{
                          paddingLeft: "35pt",
                          textIndent: "-24pt",
                          textAlign: "left"
                        }}
                      >
                        Commitments of Users and Clients
                      </h2>
                      <ol id="l17">
                        <li data-list-text="10.2.1">
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            Users/Clients agree to provide a serious and sufficiently
                            detailed Mission offer to the Carrier(s) they contact through
                            the Marketplace in order to obtain a price proposal.
                          </p>
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            To this end, Users/Clients agree to provide the Carrier with all
                            necessary details to ensure that the description of the Mission
                            offer or Order is as accurate as possible and does not mislead
                            the Carrier when preparing the price proposal.
                          </p>
                        </li>
                        <li data-list-text="10.2.2">
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            If a User or Client engages in a professional relationship with
                            a Carrier outside of the Marketplace—whether directly or
                            indirectly, regardless of the nature or duration of this
                            relationship—within 12 months of being connected to the Carrier
                            through the Company's Marketplace, this will constitute a breach
                            of the General Terms and Conditions.
                          </p>
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            In such cases, Users/Clients acknowledge and agree to pay the
                            Company a penalty of €10,000.
                          </p>

                        </li>
                      </ol>
                    </li>
                    <li data-list-text="10.3">
                      <h2
                        style={{
                          paddingLeft: "35pt",
                          textIndent: "-24pt",
                          textAlign: "left"
                        }}
                      >
                        Commitments of Carriers
                      </h2>

                      <ol id="l18">
                        <li data-list-text="10.3.1">
                          <p
                            style={{

                              textIndent: "0pt",

                            }}
                          >
                            The Carrier agrees to maintain strict confidentiality regarding
                            interactions with a User or Client and any information shared or
                            disclosed during the connection via the Marketplace (whether it
                            results in a Mission).
                          </p>
                        </li>
                        <li data-list-text="10.3.2">
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            Any Carrier subcontracting an Order remains fully responsible
                            for the contractual obligations related to said Order. The
                            Carrier must ensure that the subcontractor complies with
                            applicable legal, regulatory, and contractual requirements,
                            particularly concerning service quality, cargo security, goods
                            insurance, and meeting deadlines.
                          </p>

                          <p
                            style={{

                              textIndent: "0pt",

                            }}
                          >
                            In the event of a breach or damage resulting from
                            subcontracting, the primary Carrier will assume full
                            responsibility, including claims, compensation, or penalties
                            that may apply under the General Terms and Conditions.
                          </p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <br />
                <li data-list-text={11}>
                  <h1
                    style={{
                      paddingTop: "2pt",
                      paddingLeft: "25pt",
                      textIndent: "-24pt",
                      textAlign: "left"
                    }}
                  >
                    LIABILITY
                  </h1>
                  <ol id="l19">
                    <li data-list-text="11.1">
                      <h2
                        style={{

                          paddingLeft: "35pt",
                          textIndent: "-24pt",

                        }}
                      >
                        Liability of Users and Clients
                      </h2>

                      <ol id="l20">
                        <li data-list-text="11.1.1">
                          <p
                            style={{

                              textIndent: "0pt",

                            }}
                          >
                            Users/Clients are solely responsible for any direct or indirect
                            damages they may incur due to inaccurate, incomplete, or
                            misleading information provided during their registration or
                            failure to update such information. They assume all consequences
                            resulting from this.
                          </p>
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            Users/Clients acknowledge and agree that any notifications under
                            these General Terms and Conditions may be sent to them via the
                            email address provided when creating their Account on the
                            Marketplace.
                          </p>
                        </li>
                        <li data-list-text="11.1.2">
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            Users/Clients expressly agree not to make defamatory, offensive,
                            denigrating, slanderous, racist, xenophobic, immoral, indecent,
                            infringing, unlawful, or inappropriate comments that may harm
                            public order, third-party rights, the Company's reputation, or
                            its image. More broadly, they commit to refraining from any
                            behavior that violates laws or regulations, particularly
                            criminal laws.
                          </p>

                        </li>
                        <li data-list-text="11.1.3">
                          <p
                            style={{

                              textIndent: "0pt",

                            }}
                          >
                            Users/Clients are solely responsible for the description of the
                            Mission offer or Order for which they request a price proposal
                            from the Carrier. In the event of an error in the description of
                            the Mission offer or Order, Users/Clients will be solely
                            responsible for any additional required services and the
                            associated costs. These will be formalized as a supplemental
                            Mission through the Marketplace.
                          </p>

                        </li>
                        <li data-list-text="11.1.4">
                          <p
                            style={{

                              textIndent: "0pt",

                            }}
                          >
                            Users/Clients are solely responsible for unloading the Goods at
                            the delivery location. They must ensure that the location has
                            the necessary personnel and equipment to safely unload the
                            Goods. If this obligation is not met, Users/Clients assume all
                            consequences, including delays, damage to the Goods, or risks to
                            the safety of personnel.
                          </p>
                        </li>
                        <li data-list-text="11.1.5">
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            Users/Clients are solely responsible for concluding and
                            executing contracts related to a Mission they enter with another
                            Member through the Marketplace. The Company acts solely as an
                            intermediary to facilitate the connection.
                          </p>
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            The conclusion and execution of these contracts, which occur
                            directly between a Carrier and a User or Client, are undertaken
                            at the sole initiative and responsibility of these parties.
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li data-list-text="11.2">
                      <h1
                        style={{

                          paddingLeft: "35pt",
                          textIndent: "-26pt",

                        }}
                      >
                        Carrier's Liability
                      </h1>
                      <ol id="l21">
                        <li data-list-text="11.2.1">
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            From loading to delivery, the Carrier is fully responsible for
                            the Goods entrusted to them. The Carrier must be aware of the
                            contents based on the information provided by the User or
                            Client, ensure compliance with applicable regulations, and
                            guarantee their safety throughout transport.
                          </p>

                          <p
                            style={{

                              textIndent: "0pt",

                            }}
                          >
                            The Carrier is responsible for the loading process and must be
                            present to supervise this operation. If the Carrier does not
                            personally oversee the loading, they must indicate this on the
                            CMR (Convention on the Contract for the International Carriage
                            of Goods by Road) with the note “Loading compliant, under
                            reservation.” In case of any doubts regarding the condition or
                            conformity of the Goods, the Carrier is encouraged to take
                            photos to document the situation.
                          </p>

                          <p
                            style={{

                              textIndent: "0pt",

                            }}
                          >
                            If the Carrier observes any anomalies during loading (incorrect
                            quantity, defective condition, or non-compliance with the terms
                            of the contract), they must immediately inform the Company via
                            the Marketplace. This notification must be accompanied by the
                            necessary evidence, such as
                          </p>
                          <p
                            style={{
                              paddingTop: "3pt",

                              textIndent: "0pt",

                            }}
                          >
                            photos or detailed notes on the CMR, to ensure a prompt
                            resolution in line with contractual obligations.
                          </p>
                        </li>
                        <li data-list-text="11.2.2">
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            The Carrier must also have insurance covering the risks
                            associated with the transported Goods, in compliance with
                            applicable legal and contractual obligations. In the event of
                            damage, loss, or non-conformity, the Carrier may be held liable,
                            subject to the limitations provided by law or the transport
                            contract.
                          </p>

                        </li>
                        <li data-list-text="11.2.3">
                          <p
                            style={{

                              textIndent: "0pt",

                            }}
                          >
                            The Carrier acknowledges that the tools and technical resources
                            provided by the Company do not exempt them from their
                            responsibility concerning their legal obligations.
                          </p>

                        </li>
                      </ol>
                    </li>
                    <li data-list-text="11.3">
                      <h2
                        style={{
                          paddingLeft: "35pt",
                          textIndent: "-24pt",

                        }}
                      >
                        Company’s Liability
                      </h2>

                      <ol id="l22">
                        <li data-list-text="11.3.1">
                          <p
                            style={{

                              textIndent: "0pt",

                            }}
                          >
                            The Company makes every effort to ensure access to and proper
                            functioning of the Marketplace and Services 24/7.
                          </p>
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            The Company shall not be held liable for any service
                            interruptions, whether intentional or not. However, it commits
                            to doing its best to minimize interruptions attributable to it.
                          </p>
                        </li>
                        <li data-list-text="11.3.2">
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            The Company provides Members with tools and technical resources
                            enabling them to connect for the purpose of concluding a service
                            agreement through the Marketplace. Its responsibility is limited
                            to providing these resources, as described in the General Terms
                            and Conditions, and facilitating connections.
                          </p>

                          <p
                            style={{

                              textIndent: "0pt",

                            }}
                          >
                            The Company and the Members are independent parties, each acting
                            on their own behalf and for their own account. The Company does
                            not conclude contracts on behalf of or for the benefit of a
                            Carrier, User, or Client.
                          </p>
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            Since the Company is not a party to any contracts related to a
                            Mission concluded between Carriers and Users or Clients, these
                            parties are solely responsible for any difficulties, claims, or
                            disputes arising during the conclusion and/or execution of said
                            contracts. Consequently, each party releases the Company from
                            any liability for the direct or indirect consequences resulting
                            directly or indirectly from the connection, conclusion, and/or
                            execution of such contracts between a Carrier and a User or
                            Client.
                          </p>

                        </li>
                        <li data-list-text="11.3.3">
                          <p
                            style={{

                              textIndent: "0pt",

                            }}
                          >
                            The Company makes every effort to ensure the accuracy and
                            validity of the information and documents provided by Carriers
                            on the Marketplace, as long as they are based in France.
                            However, the Company shall not be held responsible in any way
                            for violations of obligations incumbent on Carriers,
                            particularly regarding compliance with labor laws and
                            transparency rules, or for any resulting damages.
                          </p>

                          <p
                            style={{

                              textIndent: "0pt",

                            }}
                          >
                            Thus, the Company cannot be held liable for false, misleading,
                            or outdated information communicated to it by the Carrier.
                          </p>
                        </li>
                        <li data-list-text="11.3.4">
                          <p
                            style={{


                              textIndent: "0pt",

                            }}
                          >
                            To optimize its operations and ensure service quality in line
                            with the evolving digital sector, the Company regularly updates
                            the features and functionalities of its Platform. Therefore, the
                            Company may, at any time, add, modify, remove, or replace
                            certain features.
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li data-list-text="11.4">
                      <h2
                        style={{
                          paddingTop: "3pt",
                          paddingLeft: "35pt",
                          textIndent: "-24pt",
                          textAlign: "left"
                        }}
                      >
                        Liability in Case of Theft
                      </h2>

                      <p
                        style={{


                        }}
                      >
                        In the event of theft of Goods during transport, the Carrier and the
                        User or Client must follow the following procedure:
                      </p>
                      <ul id="l23">
                        <li data-list-text="">
                          <h2 style={{ paddingTop: "13pt", textAlign: "left" }}>
                            Immediate reporting<span className="p">:</span>
                          </h2>
                          <p
                            style={{

                              textIndent: "0pt",
                              textAlign: "left"
                            }}
                          >
                            The Carrier must immediately report any theft or suspected theft
                            as soon as they become aware of it, both to the competent
                            authorities and the Company, providing a detailed account of the
                            circumstances.
                          </p>
                        </li>
                        <li data-list-text="">
                          <h2 style={{ lineHeight: "14pt", textAlign: "left" }}>
                            Carrier’s responsibility<span className="p">:</span>
                          </h2>
                          <p
                            style={{

                              textIndent: "0pt",
                              textAlign: "left"
                            }}
                          >
                            In accordance with their contractual obligations, the Carrier
                            must have insurance against theft risks. A copy of the
                            declaration made to their insurer must be sent to the Company.
                          </p>
                        </li>
                        <li data-list-text="">
                          <h2 style={{ lineHeight: "14pt", textAlign: "left" }}>
                            Active Cooperation<span className="p">:</span>
                          </h2>
                          <p
                            style={{

                              textIndent: "0pt",
                              textAlign: "left"
                            }}
                          >
                            The parties must collaborate to facilitate the necessary
                            procedures, including locating the Goods, filing a claim with
                            the insurer, or resolving the dispute.
                          </p>
                        </li>
                        <li data-list-text="">
                          <h2 style={{ lineHeight: "14pt", textAlign: "left" }}>
                            Required Documentation<span className="p">:</span>
                          </h2>
                          <p
                            style={{

                              textIndent: "0pt",
                              textAlign: "left"
                            }}
                          >
                            The Carrier must provide all necessary documents for managing
                            the case (CMR, police report, insurance certificate, etc.).
                          </p>
                        </li>
                        <li data-list-text="">
                          <h2 style={{ lineHeight: "14pt", textAlign: "left" }}>
                            Limitation of Liability<span className="p">:</span>
                          </h2>
                          <p
                            style={{

                              textIndent: "0pt",

                            }}
                          >
                            The Company acts as an intermediary and cannot be held liable
                            for thefts unless proven otherwise due to its fault. Primary
                            liability during transport lies with the Carrier, as per the
                            terms of their contract.
                          </p>
                        </li>
                        <li data-list-text="">
                          <h2 style={{ lineHeight: "14pt", }}>
                            Resolution and Compensation<span className="p">:</span>
                          </h2>
                        </li>
                      </ul>
                      <p
                        style={{

                          textIndent: "0pt",
                          textAlign: "left"
                        }}
                      >
                        Once administrative procedures are completed, the Carrier or their
                        insurer will compensate the affected User or Client, according to
                        the conditions provided in the insurance contract.
                      </p>

                    </li>
                  </ol>
                </li>
                <br />
                <li data-list-text={12}>
                  <h1
                    style={{
                      paddingLeft: "30pt",
                      textIndent: "-28pt",

                    }}
                  >
                    INSURANCE
                  </h1>
                  <p
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    The liability of the Platform Operator is limited to its role as an
                    intermediary. The Operator cannot be held liable for damages, losses, or
                    disputes resulting from improperly insured transport.
                  </p>
                  <p
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    It is the Carrier's responsibility to obtain the mandatory insurance
                    associated with their activity. Users/Clients must, on their part,
                    ensure that their economic and material interests in the transported
                    goods are adequately covered by insurance. In the event of a claim, the
                    parties involved should refer to the terms of their own insurance
                    contracts. An insurance certificate may be required to ensure coverage
                    for the Goods transported by the Carriers.
                  </p>

                </li>
                <br />
                <li data-list-text={13}>
                  <h1
                    style={{
                      paddingLeft: "30pt",
                      textIndent: "-28pt",

                    }}
                  >
                    PERSONAL DATA
                  </h1>
                  <p
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    The terms for processing data related to the use of the Marketplace and
                    any other interaction with the Company are detailed in the data
                    protection policy available on the Company’s website.
                  </p>
                  <p
                    style={{ textIndent: "0pt", }}
                  >
                    This data protection policy also explains the rights of access,
                    rectification, deletion, portability,
                  </p>
                  <p
                    style={{
                      paddingTop: "3pt",

                      textIndent: "0pt",

                    }}
                  >
                    and restriction available to individuals affected by the personal data
                    processing carried out by the Company.
                  </p>
                </li>
                <br />
                <li data-list-text={14}>
                  <h1
                    style={{

                      paddingLeft: "30pt",
                      textIndent: "-28pt",
                      textAlign: "left"
                    }}
                  >
                    COOKIES
                  </h1>
                  <p
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    To ensure the proper functioning of the Marketplace and Services,
                    cookies may be placed on the Member’s device when they connect to the
                    Marketplace. Cookies are small text files stored in the browser or used
                    by the Marketplace, applications, online media, and advertisers.
                  </p>
                  <p
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    The Company has a cookie usage policy available on its website that
                    describes the different categories of cookies. The list of third-party
                    cookie providers is provided for informational purposes only and is not
                    exhaustive. This policy also explains the procedure for configuring
                    cookie preferences.
                  </p>

                </li>
                <br />
                <li data-list-text={15}>
                  <h1
                    style={{ paddingLeft: "30pt", textIndent: "-28pt", textAlign: "left" }}
                  >
                    INTELLECTUAL PROPERTY
                  </h1>
                  <ol id="l24">
                    <li data-list-text="15.1">
                      <h2
                        style={{

                          paddingLeft: "35pt",
                          textIndent: "-24pt",
                          textAlign: "left"
                        }}
                      >
                        The Marketplace
                      </h2>

                      <p
                        style={{


                        }}
                      >
                        The Marketplace and each of its components, including but not
                        limited to texts, images, videos, photographs, trademarks, logos,
                        company names, and domain names, are the exclusive property of the
                        Company or its partners. These elements are protected by
                        intellectual property laws and others, particularly copyright law.
                      </p>
                      <p
                        style={{


                        }}
                      >
                        Any reproduction or representation, in whole or in part, of the
                        Marketplace or any of its components without the Company's
                        authorization is prohibited and constitutes an infringement
                        punishable under the Intellectual Property Code.
                      </p>
                    </li>
                    <li data-list-text="15.2">
                      <h2
                        style={{

                          paddingLeft: "35pt",
                          textIndent: "-24pt",
                          textAlign: "left"
                        }}
                      >
                        Content
                      </h2>

                      <p
                        style={{

                          textIndent: "0pt",
                          textAlign: "left"
                        }}
                      >
                        By creating a profile or leaving recommendations on profiles, the
                        Member expressly authorizes the Company to use, distribute, host,
                        store, reproduce, communicate, publish, modify, adapt, translate,
                        and display these public contents on the Marketplace, social
                        networks, blogs operated by the Company, and/or on any other media
                        (including but not limited to physical and digital media, press
                        kits, commercial materials, promotional and/or advertising
                        materials), by any means, for the purposes of operation,
                        improvement, promotion, marketing, advertising of the Services and
                        the Marketplace, or for establishing partnerships. This
                        authorization is valid worldwide and for the entire duration of the
                        Member’s registration.
                      </p>

                    </li>
                  </ol>
                </li>
                <br />
                <li data-list-text={16}>
                  <h1
                    style={{ paddingLeft: "30pt", textIndent: "-28pt", textAlign: "left" }}
                  >
                    DURATION, TERMINATION, AND SANCTIONS
                  </h1>


                  <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    This contract is entered into for an indefinite period starting from the
                    Member’s acceptance of the General Terms and Conditions.
                  </p>
                  <p
                    style={{


                      textIndent: "0pt",
                      textAlign: "left"
                    }}
                  >
                    Any use of the Marketplace and Services that violates the General Terms
                    and Conditions and/or applicable laws and regulations entitles the
                    Company to suspend, without prior notice, the
                  </p>
                  <p
                    style={{
                      paddingTop: "3pt",

                      textIndent: "0pt",

                    }}
                  >
                    Member's Account or deny the Member future access to all or part of the
                    Marketplace, without prejudice to any potential damages that the Company
                    may claim.
                  </p>
                  <p
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    Furthermore, three (3) days after notifying the Member via messaging,
                    the Company may lawfully close the suspended Member’s Account, without
                    owing any compensation of any kind.
                  </p>

                  <p
                    style={{ textIndent: "0pt", }}
                  >
                    These measures may be applied in the following cases:
                  </p>

                  <ul id="l25">
                    <li data-list-text="">
                      <p style={{ lineHeight: "14pt", textAlign: "left" }}>
                        Providing false information by the Member (documents, location,
                        experience, etc.);
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{ lineHeight: "14pt", textAlign: "left" }}>
                        Insulting remarks towards another Member or an employee of the
                        Company;
                      </p>
                    </li>
                    <li data-list-text="">
                      <p
                        style={{


                          textAlign: "left"
                        }}
                      >
                        Unfair use and/or circumvention of the Marketplace, particularly
                        contracting a Mission outside the Marketplace.
                      </p>
                    </li>
                  </ul>
                  <p
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    Similarly, if the Member is repeatedly involved in disputes or flagged
                    by the Company's Customer Service, the Company reserves the right to
                    delay the visibility of the content hosted in the search engine results,
                    suspend, or close their account.
                  </p>
                  <p
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    The closure of the Member’s Account by the Company automatically results
                    in the termination of these General Terms and Conditions.
                  </p>

                </li>
                <br />
                <li data-list-text={17}>
                  <h1
                    style={{ paddingLeft: "30pt", textIndent: "-28pt", textAlign: "left" }}
                  >
                    CUSTOMER SERVICE
                  </h1>

                  <p
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    <a href="mailto:(cs.france@mottruck.com" className="s3" target="_blank">
                      For any questions or information regarding the Marketplace and
                      Services, Members can contact the Company via the "contact" section on
                      the Website or by sending an email to the indicated email address (
                    </a>
                    <a href="mailto:(cs.france@mottruck.com" className="a" target="_blank">
                      cs.france@mottruck.com
                    </a>
                    <a href="mailto:(cs.france@mottruck.com" className="s3" target="_blank">
                      ).
                    </a>
                  </p>

                </li>
                <br />
                <li data-list-text={18}>
                  <h1
                    style={{ paddingLeft: "30pt", textIndent: "-28pt", textAlign: "left" }}
                  >
                    INVALIDITY – WAIVER
                  </h1>

                  <p
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    If any clause of this contract is declared null and void due to a change
                    in legislation, regulation, or a court decision, this shall not affect
                    the validity and enforceability of the remaining General Terms and
                    Conditions.
                  </p>
                  <p
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    Failure by the Company to exercise its rights under these General Terms
                    and Conditions does not constitute a waiver of its rights.
                  </p>

                </li>
                <br />
                <li data-list-text={19}>
                  <h1
                    style={{ paddingLeft: "30pt", textIndent: "-28pt", textAlign: "left" }}
                  >
                    MODIFICATION OF THE GENERAL TERMS AND CONDITIONS
                  </h1>
                  <p
                    style={{


                      textIndent: "0pt",

                    }}
                  >
                    The Company reserves the right to modify, at any time, at its sole
                    discretion, all or part of these General Terms and Conditions,
                    particularly Article 4 related to the description of the Services, based
                    on the evolution of the Services offered by the Company. The Member who
                    continues to use the Marketplace after these modifications have been
                    made is deemed to accept them. If the modifications made by the Company
                    are significant, the Company will inform the Members using reasonable
                    means, such as publishing a notice about these changes on the
                    Marketplace or sending an informational email to the Members.
                  </p>
                </li>
                <br />
                <li data-list-text={20}>
                  <h1
                    style={{ paddingLeft: "30pt", textIndent: "-28pt", textAlign: "left" }}
                  >
                    APPLICABLE LAW AND JURISDICTION
                  </h1>

                </li>
              </ol>

              <p style={{ textIndent: "0pt", }}>
                These General Terms and Conditions are governed by French law. Any dispute
                related to their formation, conclusion, interpretation, and/or execution
                shall be subject to the exclusive jurisdiction of the courts within the
                jurisdiction of the competent Tribunal.
              </p>
              <h1
                style={{
                  paddingTop: "2pt",
                  textIndent: "0pt",
                  textAlign: "left",
                  marginBottom: 0
                }}
              >
                ANNEX 1: List of Goods Excluded from Transport
              </h1>
              <ul id="l26">
                <li data-list-text="">
                  <h2 style={{ paddingTop: "13pt", textAlign: "left" }}>
                    Prohibitif substances
                    <span className="p">: narcotiques, psychotrope substances.</span>
                  </h2>

                </li>
                <li data-list-text="">
                  <h2 style={{ textAlign: "left" }}>
                    Illegal products<span className="p">: counterfeit goods.</span>
                  </h2>
                </li>
                <li data-list-text="">
                  <h2 style={{ textAlign: "left" }}>
                    Living beings<span className="p">: live animals.</span>
                  </h2>

                </li>
                <li data-list-text="">
                  <h2
                    style={{



                    }}
                  >
                    High-value items
                    <span className="p">
                      : jewelry, precious metals, banknotes, bearer securities, gold,
                      silver, and other valuables.
                    </span>
                  </h2>

                </li>
                <li data-list-text="">
                  <h2 style={{ textAlign: "left" }}>
                    Protected cultural property
                    <span className="p">: items of major national heritage interest.</span>
                  </h2>

                </li>
                <li data-list-text="">
                  <h2
                    style={{



                    }}
                  >
                    Regulated or sensitive items
                    <span className="p">
                      : weapons, pharmaceuticals, tobacco, asbestos, strategic goods for
                      civilian or military use, products derived from animal or plant
                      species protected by the Washington Convention.
                    </span>
                  </h2>

                </li>
                <li data-list-text="">
                  <h2 style={{ textAlign: "left" }}>
                    Dangerous items
                    <span className="p">
                      : articles whose nature or packaging may pose a danger to others.
                    </span>
                  </h2>

                </li>
                <li data-list-text="">
                  <h2
                    style={{



                    }}
                  >
                    Hazardous substances
                    <span className="p">
                      : flammable materials (alcohol, perfumes), explosives (dummy or inert
                      munitions), radioactive, corrosive, hazardous waste, compressed gases,
                      and items containing compressed gas or air (aerosols, life rafts, fire
                      extinguishers).
                    </span>
                  </h2>

                </li>
                <li data-list-text="">
                  <h2 style={{ textAlign: "left" }}>
                    Specific electronic components
                    <span className="p">: lithium batteries and cells.</span>
                  </h2>

                </li>
                <li data-list-text="">
                  <h2 style={{ textAlign: "left" }}>
                    Biological materials
                    <span className="p">
                      : perishable, infectious, or non-infectious products.
                    </span>
                  </h2>
                </li>
                <li data-list-text="">
                  <h2 style={{ textAlign: "left" }}>
                    Biological remains
                    <span className="p">: dead animals, organs, and human remains.</span>
                  </h2>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </>

  );
};

export default TermsAndConditions;
