import consoleService from "../../utility/services/consoleService";
import {
  ADD_CARRIER_DETAIL,
  ADMIN_DETAIL,
  BLOCK_CARRIER,
  CARRIER_LIST,
  DELETE_CARRIER,
  FORGOT_PASSWORD,
  LOGIN,
  RESET_PASSWORD,
  CARRIER_SENT_EMAIL,
  SIGN_UP,
  ADD_STAFF,
  STAFF_LIST,
  STAFF_SEND_EMAIL,
  STAFF_BLOCK,
  STAFF_DELETE,
  ADD_CLIENT,
  CLIENT_LIST,
  CLIENT_SEND_MAIL,
  CLIENT_BLOCK,
  CLIENT_DELETE,
  STAFF_EDIT,
  CARRIER_EDIT,
  CLIENT_EDIT,
  ADD_USER,
  USER_LIST,
  USER_BLOCK,
  USER_SEND_EMIAL,
  USER_DELETE,
  USER_EDIT,
  ADD_PRICE,
  PRICE_EDIT,
  PRICE_FETCH,
  LOGIN_STAFF,
  ADD_QUOTE,
  GET_ORDERLIST,
  DELETE_ORDER,
  GET_QUOTE,
  DELETE_QUOTE,
  EDIT_PROFILE,
  STAFF_CARRIER_LIST,
  PARTICULAR_STAFF_DETAIL,
  TOTAL_COUNT,
  ORDER_CARRIER_RATE,
  FILTER_CATEGORY,
  ACCEPT_CARRIER_PROPOSED,
  STAFF_FORGOTPASSWORD,
  STAFF_RESETPASSWORD,
  STAFF_EDIT_ACCOUNT,
  STAFF_USER_LIST,
  STAFF_CLIENT_LIST,
  STAFF_CARRIERLIST,
  STAFF_GETRESET_DETAIL,
  STAFF_TRACKING_DETAIL,
  STAFF_FILTER_LOADING,
  STAFF_ORDER_ARCHIVE,
  STAFF_SELECT_ORDER,
  STAFF_EDIT_QUOTE,
  STAFF_PATICULAT_EDIT_DETAIL,
  PARTICULAR_CARRIER,
  PARTICULAR_CLIENT,
  PARTICULAR_USER,
  UPLOAD_PROFILE_IMAGE,
  PHONENUMBER_CODE,
  GET_CARRIER_QUOTES_END_POINT,
  APPROVE_QUOTE_BY_CARRIER_END_POINT,
  DECLINE_QUOTE_BY_CARRIER_END_POINT,
  DELETE_QUOTE_BY_CARRIER_END_POINT,
  EDIT_QUOTE_BY_CARRIER_END_POINT,
  LAUNCH_QUOTE_BY_CARRIER_END_POINT,
  DECLINE_QUOTE_BY_USER_END_POINT,
  GET_CARRIER_ORDER_LIST_END_POINT,
  GET_CARRIER_ARCHIVE_LIST,
  ADD_RATING,
  SET_VIEW_ORDER_STATUS_CARRIER,
  GET_TRACKING_DETAILS,
  GET_USER_ORDER_LIST,
  SET_VIEW_ORDER_STATUS_USER,
  DELETE_ORDER_BY_USER,
  GET_USER_ARCHIVE_LIST,
  GET_CLIENT_ORDER_LIST,
  GET_CLIENT_ARCHIVE_LIST,
  GET_STAFF_ORDER_LIST,
  SEND_RESET_PASS_LINK,
  CONFIRM_RESET_PASSWORD_OTP,
  DELETE_QUOTE_FROM_LIST,
  CONTACT_US,
  GET_FILE_LINK,
  DELETE_FILE_LINK
} from "./ApiEndPoints";
import AxiosConfig from "./AxiosConfig";

const instance = AxiosConfig.instance;

// SignUp
export const getSignup = async (formData) => {
  try {
    const response = await instance.post(SIGN_UP, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("SignUp Api Error:", error);
    throw error;
  }
};

// Login
export const getLogin = async (payload) => {
  try {
    const response = await instance.post(LOGIN, payload);
    return response.data;
  } catch (error) {
    console.error("Login Api Error:", error);
    throw error;
  }
};

// Forgot Password
export const getForgotPassword = async (payload) => {
  try {
    const response = await instance.post(FORGOT_PASSWORD, payload);
    return response.data;
  } catch (error) {
    console.error("Forgot Password  Api Error:", error);
    throw error;
  }
};

// Reset Password
export const getResetPassword = async (payload) => {
  try {
    const response = await instance.post(RESET_PASSWORD, payload);
    return response.data;
  } catch (error) {
    console.error("Reset Password  Api Error:", error);
    throw error;
  }
};
// Upload Image
export const uploadImage = async (formData) => {
  try {
    const response = await instance.post(UPLOAD_PROFILE_IMAGE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Upload Image Api Error:", error);
    throw error;
  }
};

// Admin Detail
export const getAdminDetail = async (payload) => {
  try {
    const response = await instance.post(ADMIN_DETAIL, payload);
    return response.data;
  } catch (error) {
    console.error("Admin Detail  Api Error:", error);
    throw error;
  }
};

// Add Carrier Detail
export const addCarrier = async (payload) => {
  try {
    const response = await instance.post(ADD_CARRIER_DETAIL, payload);
    return response.data;
  } catch (error) {
    console.error("Add Carrier Detail  Api Error:", error);
    throw error;
  }
};
// Carrier List
export const getCarrierList = async (payload) => {
  try {
    const response = await instance.post(CARRIER_LIST, payload);
    return response.data;
  } catch (error) {
    console.error("Carrier List  Api Error:", error);
    throw error;
  }
};
// Send Email
export const sendEmail = async (payload) => {
  try {
    const response = await instance.post(CARRIER_SENT_EMAIL, payload);
    return response.data;
  } catch (error) {
    console.error("Send Email  Api Error:", error);
    throw error;
  }
};
// Edit Carrier
export const edit_Carrier = async (payload) => {
  try {
    const response = await instance.post(CARRIER_EDIT, payload);
    return response.data;
  } catch (error) {
    console.error("Edit carrier  Api Error:", error);
    throw error;
  }
};

// Block Carrier
export const blockCarrier = async (payload) => {
  try {
    const response = await instance.post(BLOCK_CARRIER, payload);
    return response.data;
  } catch (error) {
    console.error("Block Carrier Api Error:", error);
    throw error;
  }
};
// Delete
export const deleteCarrier = async (payload) => {
  try {
    const response = await instance.post(DELETE_CARRIER, payload);
    return response.data;
  } catch (error) {
    console.error("Delete Carrier Api Error:", error);
    throw error;
  }
};
// Add Staff
export const addStaff = async (payload) => {
  try {
    const response = await instance.post(ADD_STAFF, payload);
    return response.data;
  } catch (error) {
    console.error("Add Staff Api Error:", error);
    throw error;
  }
};
// Staff List
export const staff_list = async (payload) => {
  try {
    const response = await instance.post(STAFF_LIST, payload);
    return response.data;
  } catch (error) {
    console.error("Add Staff Api Error:", error);
    throw error;
  }
};
// Staff Send Email
export const staff_sendEmail = async (payload) => {
  try {
    const response = await instance.post(STAFF_SEND_EMAIL, payload);
    return response.data;
  } catch (error) {
    console.error("Add Staff Api Error:", error);
    throw error;
  }
};
// Staff Edit detial
export const staff_EditDetail = async (payload) => {
  try {
    const response = await instance.post(STAFF_EDIT, payload);
    return response.data;
  } catch (error) {
    console.error("Edit Staff Api Error:", error);
    throw error;
  }
};
// Staff Block
export const staff_Block = async (payload) => {
  try {
    const response = await instance.post(STAFF_BLOCK, payload);
    return response.data;
  } catch (error) {
    console.error("Block Staff Api Error:", error);
    throw error;
  }
};
// Staff Delelet
export const staff_Delete = async (payload) => {
  try {
    const response = await instance.post(STAFF_DELETE, payload);
    return response.data;
  } catch (error) {
    console.error("Delete Staff Api Error:", error);
    throw error;
  }
};
// Add client
export const add_Client = async (payload) => {
  try {
    const response = await instance.post(ADD_CLIENT, payload);
    return response.data;
  } catch (error) {
    console.error("Add client Api Error:", error);
    throw error;
  }
};
// Client List
export const client_List = async (payload) => {
  try {
    const response = await instance.post(CLIENT_LIST, payload);
    return response.data;
  } catch (error) {
    console.error("client list Api Error:", error);
    throw error;
  }
};
// Client send mail
export const client_SendMail = async (payload) => {
  try {
    const response = await instance.post(CLIENT_SEND_MAIL, payload);
    return response.data;
  } catch (error) {
    console.error("client send mail Api Error:", error);
    throw error;
  }
};
// client edit detail
export const client_EditDetail = async (payload) => {
  try {
    const response = await instance.post(CLIENT_EDIT, payload);
    return response.data;
  } catch (error) {
    console.error("client edit Api Error:", error);
    throw error;
  }
};
// Client block
export const client_Block = async (payload) => {
  try {
    const response = await instance.post(CLIENT_BLOCK, payload);
    return response.data;
  } catch (error) {
    console.error("client block  Api Error:", error);
    throw error;
  }
};
// Client delete
export const client_Delete = async (payload) => {
  try {
    const response = await instance.post(CLIENT_DELETE, payload);
    return response.data;
  } catch (error) {
    console.error("client delete Api Error:", error);
    throw error;
  }
};
// Add User
export const add_User = async (payload) => {
  try {
    const response = await instance.post(ADD_USER, payload);
    return response.data;
  } catch (error) {
    console.error("Add user Api Error:", error);
    throw error;
  }
};
// User List
export const user_List = async (payload) => {
  try {
    const response = await instance.post(USER_LIST, payload);
    return response.data;
  } catch (error) {
    console.error("User list Api Error:", error);
    throw error;
  }
};
// User Send Email
export const user_SendMail = async (payload) => {
  try {
    const response = await instance.post(USER_SEND_EMIAL, payload);
    return response.data;
  } catch (error) {
    console.error("User send Email  Api Error:", error);
    throw error;
  }
};
// User Edit
export const user_Edit = async (payload) => {
  try {
    const response = await instance.post(USER_EDIT, payload);
    return response.data;
  } catch (error) {
    console.error("User Edit Api Error:", error);
    throw error;
  }
};
// User Block
export const user_Block = async (payload) => {
  try {
    const response = await instance.post(USER_BLOCK, payload);
    return response.data;
  } catch (error) {
    console.error("User Block Api Error:", error);
    throw error;
  }
};
// User Delete
export const user_Delete = async (payload) => {
  try {
    const response = await instance.post(USER_DELETE, payload);
    return response.data;
  } catch (error) {
    console.error("User Block Api Error:", error);
    throw error;
  }
};
// Add Price
export const add_Price = async (payload) => {
  try {
    const response = await instance.post(ADD_PRICE, payload);
    return response.data;
  } catch (error) {
    console.error("Add Price Api Error:", error);
    throw error;
  }
};
// Edit Price
export const price_Edit = async (payload) => {
  try {
    const response = await instance.post(PRICE_EDIT, payload);
    return response.data;
  } catch (error) {
    console.error("Edit Price Api Error:", error);
    throw error;
  }
};
// Price Fetch
export const price_Fetch = async (payload) => {
  try {
    const response = await instance.post(PRICE_FETCH, payload);
    return response.data;
  } catch (error) {
    console.error("Fetch Price Api Error:", error);
    throw error;
  }
};

// Edit Admin
export const edit_AdminProfile = async (formData) => {
  try {
    const response = await instance.post(EDIT_PROFILE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Edit Admin Api Error:", error);
    throw error;
  }
};
// Paticular Carrier
export const particular_Carrier = async (payload) => {
  try {
    const response = await instance.post(PARTICULAR_CARRIER, payload);
    return response.data;
  } catch (error) {
    console.error("Paticular Carrier Api Error:", error);
    throw error;
  }
};
// Paticular Client
export const particular_Client = async (payload) => {
  try {
    const response = await instance.post(PARTICULAR_CLIENT, payload);
    return response.data;
  } catch (error) {
    console.error("Paticular Client Api Error:", error);
    throw error;
  }
};
// Paticular User
export const particular_User = async (payload) => {
  try {
    const response = await instance.post(PARTICULAR_USER, payload);
    return response.data;
  } catch (error) {
    console.error("Paticular User Api Error:", error);
    throw error;
  }
};

// ++++++++++++++++++++++++++++++++++++++++STAFF+++++++++++++++++++++++++++++++++++++

// Login Staff
export const login_Staff = async (payload) => {
  try {
    const response = await instance.post(LOGIN_STAFF, payload);
    return response.data;
  } catch (error) {
    console.error("Login Staff Api Error:", error);
    throw error;
  }
};
// Quote List
export const get_QuoteList = async (payload) => {
  try {
    const response = await instance.post(GET_QUOTE, payload);
    return response.data;
  } catch (error) {
    console.error("Quote List Api Error:", error);
    throw error;
  }
};
// Delete Quote
export const delete_Quote = async (payload) => {
  try {
    const response = await instance.post(DELETE_QUOTE, payload);
    return response.data;
  } catch (error) {
    console.error("Quote List Api Error:", error);
    throw error;
  }
};
// Add Quote
export const add_Quote = async (payload) => {
  try {
    const response = await instance.post(ADD_QUOTE, payload);
    return response.data;
  } catch (error) {
    console.error("Add Quote Api Error:", error);
    throw error;
  }
};
// Order List
export const get_OrderList = async (payload) => {
  try {
    const response = await instance.post(GET_ORDERLIST, payload);
    return response.data;
  } catch (error) {
    console.error("Order List Api Error:", error);
    throw error;
  }
};
// Order Delete
export const order_Delete = async (payload) => {
  try {
    const response = await instance.post(DELETE_ORDER, payload);
    return response.data;
  } catch (error) {
    console.error("Order delete Api Error:", error);
    throw error;
  }
};
// Staff Carrier List
export const staff_CarrierList = async (payload) => {
  try {
    const response = await instance.post(STAFF_CARRIER_LIST, payload);
    return response.data;
  } catch (error) {
    console.error("Staff Carrier List Api Error:", error);
    throw error;
  }
};
// Staff Paticular Detial
export const staff_PaticularDetail = async (payload) => {
  try {
    const response = await instance.post(PARTICULAR_STAFF_DETAIL, payload);
    return response.data;
  } catch (error) {
    console.error("Staff Paticular Detial Api Error:", error);
    throw error;
  }
};
// Order Count
export const staff_OrderCount = async (payload) => {
  try {
    const response = await instance.post(TOTAL_COUNT, payload);
    return response.data;
  } catch (error) {
    console.error("Order Count Api Error:", error);
    throw error;
  }
};
// Order Carrier Rate
export const staff_OrderCarrierRate = async (payload) => {
  try {
    const response = await instance.post(ORDER_CARRIER_RATE, payload);
    return response.data;
  } catch (error) {
    console.error("Order Carrier Rate Api Error:", error);
    throw error;
  }
};
// Filter Category
export const staff_FilterCategory = async (payload) => {
  try {
    const response = await instance.post(FILTER_CATEGORY, payload);
    return response.data;
  } catch (error) {
    console.error("Filter Category Api Error:", error);
    throw error;
  }
};
// Accept Proposed
export const staff_AcceptProposed = async (payload) => {
  try {
    const response = await instance.post(ACCEPT_CARRIER_PROPOSED, payload);
    return response.data;
  } catch (error) {
    console.error("Accept Proposed Api Error:", error);
    throw error;
  }
};
// send_link_for_reset_pass
export const send_link_for_reset_pass = async (payload) => {
  try {
    const response = await instance.post(SEND_RESET_PASS_LINK, payload);
    return response.data;
  } catch (error) {
    console.error("Staff send_link_for_reset_pass Api Error:", error);
    throw error;
  }
};

export const confirm_reset_password_otp = async (payload) => {
  try {
    const response = await instance.post(CONFIRM_RESET_PASSWORD_OTP, payload);
    return response.data;
  } catch (error) {
    console.error("Staff send_link_for_reset_pass Api Error:", error);
    throw error;
  }
};
// Staff Get ResetPassword Detail
export const staff_GetResetPasswordDetail = async (payload) => {
  try {
    const response = await instance.post(STAFF_GETRESET_DETAIL, payload);
    return response.data;
  } catch (error) {
    console.error("Staff Get ResetPassword Detail Api Error:", error);
    throw error;
  }
};
// Staff ResetPassword
export const staff_ResetPassword = async (payload) => {
  try {
    const response = await instance.post(STAFF_RESETPASSWORD, payload);
    return response.data;
  } catch (error) {
    console.error("Staff ResetPassword Api Error:", error);
    throw error;
  }
};
// Staff Edit Account
export const staff_EditAccount = async (payload) => {
  try {
    const response = await instance.post(STAFF_EDIT_ACCOUNT, payload);
    return response.data;
  } catch (error) {
    console.error("Staff EditAccount Api Error:", error);
    throw error;
  }
};
// Staff User List
export const staff_UserList = async (payload) => {
  try {
    const response = await instance.post(STAFF_USER_LIST, payload);
    return response.data;
  } catch (error) {
    console.error("Staff User List Api Error:", error);
    throw error;
  }
};
// Staff Client List
export const staff_ClientList = async (payload) => {
  try {
    const response = await instance.post(STAFF_CLIENT_LIST, payload);
    return response.data;
  } catch (error) {
    console.error("Staff Client List  Api Error:", error);
    throw error;
  }
};
// Staff Carrier List
export const staff_GiveCarrierList = async (payload) => {
  try {
    const response = await instance.post(STAFF_CARRIERLIST, payload);
    return response.data;
  } catch (error) {
    console.error("Staff Carrier List Api Error:", error);
    throw error;
  }
};
// Staff Tracking Detail
export const staff_GetTrackingDetail = async (payload) => {
  try {
    const response = await instance.post(STAFF_TRACKING_DETAIL, payload);
    return response.data;
  } catch (error) {
    console.error("Staff Tracking Detail Api Error:", error);
    throw error;
  }
};
// Staff Tracking Detail
export const staff_FilterLoading = async (payload) => {
  try {
    const response = await instance.post(STAFF_FILTER_LOADING, payload);
    return response.data;
  } catch (error) {
    console.error("Staff Filter Loading Api Error:", error);
    throw error;
  }
};
// Staff Order Archive
export const staff_ArchiveOrder = async (payload) => {
  try {
    const response = await instance.post(STAFF_ORDER_ARCHIVE, payload);
    return response.data;
  } catch (error) {
    console.error("Staff Archive order Api Error:", error);
    throw error;
  }
};
// Staff Select Order
export const staff_SelectOrder = async (payload) => {
  try {
    const response = await instance.post(STAFF_SELECT_ORDER, payload);
    return response.data;
  } catch (error) {
    console.error("Staff select order Api Error:", error);
    throw error;
  }
};
// Staff Edit Quote
export const staff_EditQuote = async (payload) => {
  try {
    const response = await instance.post(STAFF_EDIT_QUOTE, payload);
    return response.data;
  } catch (error) {
    console.error("Staff Edit Quote Api Error:", error);
    throw error;
  }
};
// Staff Paticilar edit detail
export const staff_PaticularEditDetail = async (payload) => {
  try {
    const response = await instance.post(STAFF_PATICULAT_EDIT_DETAIL, payload);
    return response.data;
  } catch (error) {
    console.error("Staff Paticilar edit detail Api Error:", error);
    throw error;
  }
};
// PhoneNumberCode
export const country_PhoneNumberCode = async (payload) => {
  try {
    const response = await instance.post(PHONENUMBER_CODE, payload);
    return response.data;
  } catch (error) {
    console.error("Phone Number Code Api Error:", error);
    throw error;
  }
};
// GET_CARRIER_QUOTES_
export const get_carrier_quotes = async (payload) => {
  try {
    const response = await instance.post(GET_CARRIER_QUOTES_END_POINT, payload);
    return response.data;
  } catch (error) {
    consoleService("get_carrier_quotes", error);
    throw error;
  }
};

export const deleteCarrierQuotes = async (payload) => {
  try {
    const response = await instance.post(DELETE_QUOTE_FROM_LIST, payload);
    console.log(response , 'remove response is working ---')
    return response.data;
  } catch (error) {
    consoleService("Delete data from list error", error);
    throw error;
  }
};

// APPROVE_QUOTE_BY_CARRIER
export const approve_quote_by_carrier = async (payload) => {
  try {
    const response = await instance.post(
      APPROVE_QUOTE_BY_CARRIER_END_POINT,
      payload
    );
    return response.data;
  } catch (error) {
    consoleService("approve_quote_by_carrier", error);
    throw error;
  }
};

// DECLINE_QUOTE_BY_CARRIER
export const decline_quote_by_carrier = async (payload) => {
  try {
    const response = await instance.post(
      DECLINE_QUOTE_BY_CARRIER_END_POINT,
      payload
    );
    return response.data;
  } catch (error) {
    consoleService("decline_quote_by_carrier", error);
    throw error;
  }
};

// DELETE_QUOTE_BY_CARRIER
export const delete_quote_by_carrier = async (payload) => {
  try {
    const response = await instance.post(
      DELETE_QUOTE_BY_CARRIER_END_POINT,
      payload
    );
    return response.data;
  } catch (error) {
    consoleService("delete_quote_by_carrier", error);
    throw error;
  }
};

// EDIT_QUOTE_BY_CARRIER
export const edit_quote_by_carrier = async (payload) => {
  try {
    const response = await instance.post(
      EDIT_QUOTE_BY_CARRIER_END_POINT,
      payload
    );
    return response.data;
  } catch (error) {
    consoleService("edit_quote_by_carrier", error);
    throw error;
  }
};

// LAUNCH_QUOTE_BY_CARRIER
export const launch_quote_by_carrier = async (payload) => {
  try {
    const response = await instance.post(
      LAUNCH_QUOTE_BY_CARRIER_END_POINT,
      payload
    );
    return response.data;
  } catch (error) {
    consoleService("launch_quote_by_carrier", error);
    throw error;
  }
};

// DECLINE_QUOTE_BY_USER_END_POINT
export const decline_quote_by_user = async (payload) => {
  try {
    const response = await instance.post(
      DECLINE_QUOTE_BY_USER_END_POINT,
      payload
    );
    return response.data;
  } catch (error) {
    consoleService("decline_quote_by_carrier", error);
    throw error;
  }
};

// GET LOCATIONS DISTANCE

export const calculateDistance = async (lng1, lat1, lng2, lat2) => {
  const apiKey = "AIzaSyB3cLetAkii900cvYPIrvxILEJ6qUhpG4I";
  const origin = `${lat1},${lng1}`; // San Francisco
  const destination = `${lat2},${lng2}`; // Los Angeles

  const url = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${origin}&destinations=${destination}&key=${apiKey}`;

  try {
    const response = await instance.get(url);
    const result = response.data;
    const distanceInMeters = result.rows[0].elements[0].distance.value;
    consoleService("distance in  distanceInMeters = ", distanceInMeters);
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
};

// GET_CARRIER_ORDER_LIST
export const get_carrier_order_list = async (payload) => {
  try {
    const response = await instance.post(
      GET_CARRIER_ORDER_LIST_END_POINT,
      payload
    );
    return response.data;
  } catch (error) {
    consoleService("get_carrier_order_list", error);
    throw error;
  }
};

// GET_CARRIER_ARCHIVE_LIST
export const get_carrier_archive_list = async (payload) => {
  try {
    const response = await instance.post(GET_CARRIER_ARCHIVE_LIST, payload);
    return response.data;
  } catch (error) {
    consoleService("get_carrier_archive_list", error);
    throw error;
  }
};

// GET_RATING
export const add_rating = async (payload) => {
  try {
    const response = await instance.post(ADD_RATING, payload);
    return response.data;
  } catch (error) {
    consoleService("add_rating", error);
    throw error;
  }
};

// SET_VIEW_ORDER_STATUS_CARRIER
export const set_view_order_status_carrier = async (payload) => {
  try {
    const response = await instance.post(
      SET_VIEW_ORDER_STATUS_CARRIER,
      payload
    );
    return response.data;
  } catch (error) {
    consoleService("set_view_order_status_carrier", error);
    throw error;
  }
};

// GET TRACKING DETAILS
export const get_tracking_details = async (payload) => {
  try {
    const response = await instance.post(GET_TRACKING_DETAILS, payload);
    return response.data;
  } catch (error) {
    consoleService("get_tracking_details", error);
    throw error;
  }
};

// GET USER ORDER LIST
export const get_user_order_list = async (payload) => {
  try {
    const response = await instance.post(GET_USER_ORDER_LIST, payload);
    return response.data;
  } catch (error) {
    consoleService("get_user_order_list", error);
    throw error;
  }
};

// GET STAFF ORDER LIST
export const get_staff_order_list = async (payload) => {
  try {
    const response = await instance.post(GET_STAFF_ORDER_LIST, payload);
    return response.data;
  } catch (error) {
    consoleService("get_staff_order_list", error);
    throw error;
  }
};

// SET_VIEW_ORDER_STATUS_USER
export const set_view_order_status_user = async (payload) => {
  try {
    const response = await instance.post(SET_VIEW_ORDER_STATUS_USER, payload);
    return response.data;
  } catch (error) {
    consoleService("set_view_order_status_user", error);
    throw error;
  }
};

// DELETE_QUOTE_BY_USER
export const delete_quote_by_user = async (payload) => {
  try {
    const response = await instance.post(DELETE_ORDER_BY_USER, payload);
    return response.data;
  } catch (error) {
    consoleService("delete_quote_by_user", error);
    throw error;
  }
};

// GET_USER_ARCHIVE_LIST
export const get_user_archive_list = async (payload) => {
  try {
    const response = await instance.post(GET_USER_ARCHIVE_LIST, payload);
    return response.data;
  } catch (error) {
    consoleService("get_user_archive_list", error);
    throw error;
  }
};

// GET CLIENT ORDER LIST
export const get_client_order_list = async (payload) => {
  try {
    const response = await instance.post(GET_CLIENT_ORDER_LIST, payload);
    return response.data;
  } catch (error) {
    consoleService("get_client_order_list", error);
    throw error;
  }
};

// GET_CLIENT_ARCHIVE_LIST
export const get_client_archive_list = async (payload) => {
  try {
    const response = await instance.post(GET_CLIENT_ARCHIVE_LIST,payload);
    return response.data;
  } catch (error) {
    consoleService("get_client_archive_list", error);
    throw error;
  }
};



// Contactus
export const contact_us = async (payload) => {
  try {
    const response = await instance.post(CONTACT_US, payload);
    return response.data;
  } catch (error) {
    console.error("Login Api Error:", error);
    throw error;
  }
};


export const upload_file_getLink = async (payload, onUploadProgress) => {
  try {
    const response = await instance.post(GET_FILE_LINK, payload, {
      onUploadProgress: (progressEvent) => {
        if (onUploadProgress) {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          onUploadProgress(progress);  // Call the progress callback to update the UI
        }
      }
    });
    return response.data;
  } catch (error) {
    consoleService("get_client_archive_list", error); 
    throw error;
  }
};


export const deleteFileS3 = async (payload) => {
  try {
    const response = await instance.post(DELETE_FILE_LINK, payload);
    return response.data;
  } catch (error) {
    consoleService("get_client_archive_list", error);  
    throw error;
  }
};